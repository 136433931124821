import React, { useEffect, useState } from "react";
import agiyejan from "./images/stts/agiyejan.png";
import listenspeaker from "./images/stts/listenspeaker.png";
import mobilehome from "./images/stts/home.png";
import { getAudioAndScripts, postAudio, getShununAudio, getYesReasons, getNoReasons } from "./crowd_source.service";
import { ToastFailedMsg, ToastSuccessMsg, ToastWarningMsg } from "../../common/toast";
import LongRunningLoading from "../../common/LongRunningLoading";
import { GrStop } from "react-icons/gr";
import { getLoggedInUserId } from "../../common/http";
import ModalComponent from "./Modal.Component";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as rdd from 'react-device-detect'
import { useGeolocated } from "react-geolocated";

import Carousel from "react-bootstrap/Carousel";
// import "./bodycontent.css";
import "./tabconversation.css";
// import Footer from './Footer.js'

// import LinearProgress from "@mui/material/LinearProgress";
// or
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import bolun from "./images/stts/olun.png";
import bolunbottom from "./images/stts/bolunbottom.png";
import sunun from "./images/stts/sunun.png";
import readrule from "./images/stts/readrule.png";
import clickpoint from "./images/stts/clickhover.png";
import listenspeakeractive from "./images/stts/listenspeakeractive.png";

import reload from "./images/stts/reload.png";
import disjomadin from "./images/stts/disjomadin.png";
import check from "./images/stts/check.png";
import patharrow from "./images/stts/patharrow.png";
import hatick from "./images/stts/hatick.png";
import nathick from "./images/stts/nathick.png";
import hatickdisable from "./images/stts/hatickdisable.png";
import natickdisable from "./images/stts/natickdisable.png";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import { components } from "react-select";
import Timer from "./Timer";
import Spinner from 'react-bootstrap/Spinner';

export default function Sunun() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const [ToggleState, setToggleState] = useState(1);

  const toggleTab = (index: any) => {
    setToggleState(index);
  };

  const getActiveClass = (index: any, className: any) =>
    ToggleState === index ? className : "";

  // play stop button show hide

  const [btnState, setBtnState] = useState(false);

  function handleClick() {
    setBtnState((btnState) => !btnState);
  }

  //   let toggleClassCheck = btnState ? "clickstop" : "clickimg";
  // let toggleClassCheck = btnState ? "clickstoplisten" : "clickimglisten";
  let toggleClassChecklisten = btnState
    ? "clickstoplistenplay"
    : "clickimglistenplay";
  // Play stop end here

  // const [btnStatejomadin, setBtnStatejomadin] = useState(false)

  // function handleClickjomadin(){
  //   setBtnStatejomadin(btnStatejomadin => !btnStatejomadin);
  // }

  // let toggleClassCheckjomadin = btnStatejomadin? 'clickstop': 'btnactive';

  // speaking area show hide

  const [show, setShow] = useState(false);
  // const [showclose, setShowclose] = useState(false);
  // speaking area show hide end

  // Lacture button active

  const [showlacture, setShowlacture] = useState(false);

  let lectureactive = showlacture ? "lectureactive" : "";

  // Show doito and toito
  const [showdoito, setShowdoito] = useState(false);

  let showdoitoactive = showdoito ? "doitotoactive" : "";
  // Show doito and toito
  const [showtoito, setShowtoito] = useState(false);

  let showtoitoactive = showtoito ? "doitotoactive" : "";

  // Show doito and toito
  // Lacture button active
  const [btnStateclose, setBtnStateclose] = useState(true);

  // Lacture button inactive

  function handleClickclose() {
    setBtnStateclose((btnStateclose) => !btnStateclose);
  }

  let toggleClassCheckclose = btnStateclose
    ? " kotopokothon "
    : " cothopokothongroupnone";
  // let toggleClassCheckcloseshow = btnStateclose? '  ': ' cothopokothongroupbtn';
  let kbtactive = btnStateclose ? "kbtactive" : "afterclick";
  let divcontenthide = btnStateclose ? "overallhide" : "overallcontent";

  // =============Modal State start here ==========================

  const [openModal, setOpenModal] = useState(false);

  // =============Modal State start here ==========================
  let [pageLoader, setPageLoader]: any = useState(true);

  let [isLoading, setIsLoading]: any = useState(true);
  let [count, setCount]: any = useState(0);
  let [yesnobutton, setYesNoButton]: any = useState(true);
  let [audioUrls, setAudioUrls]: any = useState([]);
  let [scriptDescription, setScriptDescription]: any = useState([]);
  let [audioTime, setAudioTime]: any = useState([]);
  let [audioId, setAudioId]: any = useState([]);
  let [isPlaying, setIsPlaying]: any = useState(false);
  let [audioSubmitNumber, setAudioSubmitNumber]: any = useState(1);
  let [audioSubmitable, setAudioSubmitable]: any = useState(false);
  let [submitted, setSubmitted]: any = useState(false);
  let [checkedCount, setCheckedCount]: any = useState(1);
  let [component, setComponent]: any = useState([]);
  let [data, setData]: any = useState({
    checkerId: getLoggedInUserId(),
  });
  let [audio, setAudio]: any = useState(null);
  let [disableListen, setDisableListen]: any = useState(false);
  let [speakerId, setSpeakerId]: any = useState(null);
  const [checkerIdFound, setCheckerIdFound]: any = useState(false);
  const [reasonModal, setReasonModal]: any = useState(false);
  const navigate = useNavigate();
  const id = getLoggedInUserId();
  let [yesnoCount, setYesnoCount]: any = useState(0);
  let [showReasonDropDown, setShowReasonDropDown]: any = useState(false)
  const [listenOption, setListenOption]: any = useState(2);
  const [reasonShow, setReasonShow]: any = useState("");
  const yes_reasons = [
    "সঠিক",
    "শ্বাসের শব্দ",
    "ভলিউম কম",
    "বানান ভুল",
    "অন্যদের কথা",
    "অন্য আওয়াজ"
  ]


  const no_reasons = [
    "স্ক্রিপ্ট-স্পিচে অমিল",
    "অপ্রমিত উচ্চারণ",
    "অস্পষ্ট",
    "শুধু নয়েজ ",
    "আংশিক রেকর্ড",
    "অন্যান্য"
  ]

  useEffect(() => {
    console.log("--data: ", data);
  }, [data])

  useEffect(() => {
    //Runs only on the first render

    (async () => {
      setSubmitted(false);
      console.log("USEr Id ", getLoggedInUserId());

      resetState();
      if (!submitted) {
        console.log("getAudioWithScripts called from 217");
        await getAudioWithScripts();
      }

      // return()=>{
      //     setSubmitted(false);
      // }
    })();
  }, [submitted]);

  useEffect(() => {
    setBtnState(false);

    const timer = setInterval(() => {
      setProgress((oldProgress: any) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);


  useEffect(() => {
    if (checkerIdFound) {
      (async () => {
        console.log("CheckerId found ");
        let speaker_annotation: any = new FormData();
        speaker_annotation.append("data", JSON.stringify(data));
        speaker_annotation.append("details", JSON.stringify(detail));
        console.log("Data ", JSON.stringify(data));

        let pos = await postAudio(speaker_annotation);

        if (pos.status == 200) {
          ToastSuccessMsg("ডেটা প্রসেসিং চলছে। আপডেট জানতে কিছুক্ষণ পর ড্যাশবোর্ড চেক করুন।");
          setTimeout(() => navigatePage(), 3000);
        } else {
          ToastFailedMsg("জমা হয়নি");
          setTimeout(() => window.location.reload(), 2000);
        }
      })();
    }
  }, [checkerIdFound]);

  const [yesReasons, setYesReasons] = useState(["সঠিক", "শ্বাসের শব্দ", "ভলিউম কম", "বানান ভুল", "অন্যদের কথা", " অন্য আওয়াজ", "বেশি ধীরে পড়া", "শব্দ দীর্ঘ করা", "বেশি পজ দেওয়া"]);
  const [noReasons, setNoReasons] = useState(["স্ক্রিপ্ট-স্পিচে অমিল", "অপ্রমিত উচ্চারণ", "অস্পষ্ট", "শুধু নয়েজ", "আংশিক রেকর্ড", "ভয়েস নেই", "অন্যান্য"]);

  const [speechAreAvailable, setSpeechAreAvailable]: any = useState(false);

  const getAudioWithScripts = async () => {

    let res = await getAudioAndScripts(getLoggedInUserId());



    if (res.status == 200) {
      try {
        let { data } = await res.json();
        console.log("Data ", { data });
        // console.log("keys ",[Object.keys(data)]);

        data.forEach((item: any) => {
          console.log("Description", item.voiceUrl, item.description);
          setAudioUrls((prevState: any) => [...prevState, item.voiceUrl]);
          setScriptDescription((prevState: any) => [
            ...prevState,
            item.description,
          ]);
          setAudioId((prevState: any) => [...prevState, item.id]);
          setAudioTime((prevState: any) => [...prevState, item.duration]);
        });

        setIsLoading(false);
        setSpeechAreAvailable(true);
      }
      catch (err) {
        console.warn(err);
        setSpeechAreAvailable(false);
      }

    }
    else {
      setSpeechAreAvailable(false);
    }

    // let res2 = await getYesReasons();
    // if (res2.status == 200) {
    //   let { data } = await res2.json();
    //   console.log("reasonslistyes ", { data });
    //   setYesReasons(data);
    // }

    // let res3 = await getNoReasons();
    // if (res3.status == 200) {
    //   let { data } = await res3.json();
    //   console.log("reasonslistno ", { data });
    //   setNoReasons(data);
    // }
  };

  let time = new Date();
  time.setSeconds(time.getSeconds() + 600);
  const resetState = () => {
    //time.setSeconds(time.getSeconds() + 600);
    setIsLoading(true);
    setCount(0);
    setYesNoButton(true);
    setAudioUrls([]);
    setScriptDescription([]);
    setAudioTime([]);
    setAudioId([]);
    setIsPlaying(false);
    setAudioSubmitNumber(1);
    setSubmitted(false);
    setCheckedCount(1);
    setComponent([]);
    setData({
      checkerId: id,
    });
    setAudio(null);
    setDisableListen(false);
    setShowReasonDropDown(false);

  };

  const navigatePage = () => {
    navigate("/log-in");
    window.location.reload();
  };

  const reload = () => {
    //setTimeout(() => setSubmitted(true), 2000);
    setTimeout(() => window.location.reload(), 2000);
    // setBolun(false);
  };

  const dataSubmitAfterRegistration = (id: any) => {
    console.log("Sunun Id ", id);

    let a: any = data;
    a.checkerId = id;
    console.log("Here a is ", a);
    setData(a);

    setCheckerIdFound(true);
  };


  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      // userDecisionTimeout: 5000,
    });


  let example = "kaj kor bhai"
  let detailString: any = {};
  let [detail, setDetail]: any = useState(null);
  const getDetails = async () => {
    example = example + "!!!!!";
    let useragent: any = {};
    let geoData: any = {};
    let deviceString: any = {};


    useragent.isMobile = rdd.isMobile;
    useragent.isAndroid = rdd.isAndroid;
    useragent.isIOS = rdd.isIOS;
    useragent.isIOS13 = rdd.isIOS13;
    useragent.isDesktop = rdd.isDesktop;
    useragent.isWindows = rdd.isWindows;
    useragent.isMacOs = rdd.isMacOs;
    useragent.isChrome = rdd.isChrome;
    useragent.isFirefox = rdd.isFirefox;
    useragent.isEdge = rdd.isEdge;
    useragent.isSamsungBrowser = rdd.isSamsungBrowser;
    useragent.isBrowser = rdd.isBrowser;
    useragent.mobileVendor = rdd.mobileVendor;
    useragent.mobileModel = rdd.mobileModel;
    useragent.osName = rdd.osName;
    useragent.osVersion = rdd.osVersion;
    useragent.fullBrowserVersion = rdd.fullBrowserVersion;
    console.log("Selected Id ", data);


    let geoDataString: any;

    if (!isGeolocationAvailable) {
      console.log("Your browser does not support Geolocation");
    } else if (!isGeolocationEnabled) {
      console.log("Geolocation is not enabled");
    } else {
      console.log("Latitude !!!", coords?.latitude);

      geoData.latitude = coords?.latitude;
      geoData.longitude = coords?.longitude;
      geoData.altitude = coords?.altitude;
      geoData.heading = coords?.heading;
      geoData.speed = coords?.speed;

      //geoDataString=JSON.stringify(geoData);
      //console.log(geoDataString);
    }

    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
    } else {
      let flag = 0;
      // List cameras and microphones.
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          devices.forEach((device, index, arr) => {
            if (!(device.deviceId === "default" || device.deviceId === "communications") && (device.kind === "audiooutput") && flag === 0) {
              console.log("i want this device", device);
              deviceString[device.kind] = device.label;


              detailString.userAgent = JSON.stringify(useragent);
              detailString.geoLocation = JSON.stringify(geoData);
              detailString.device = JSON.stringify(deviceString);
              detailString.reason = null;//we can assign reason here
              console.log("detailString", JSON.stringify(detailString));
              setDetail(detailString);
              flag = 1;
            }

            //console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
            //if(device.)
          });
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });
    }
  }

  const [showSubmitSpinner, setShowSubmitSpinner]: any = useState(false);
  const onSubmit = async () => {
    if (!showSubmitSpinner) {
      //console.log("kaj kore?", detail);
      //console.log(rdd.isAndroid, rdd);
      setShowSubmitSpinner(true);
      if (getLoggedInUserId() === undefined) {
        setShow(true);
        return;
      }


      let speaker_annotation: any = new FormData();
      speaker_annotation.append("data", JSON.stringify(data));
      speaker_annotation.append("details", JSON.stringify(detail));

      console.log("Data ", JSON.stringify(data));
      let pos = await postAudio(speaker_annotation);

      if (pos.status === 200) {
        ToastSuccessMsg("ডেটা প্রসেসিং চলছে। আপডেট জানতে কিছুক্ষণ পর ড্যাশবোর্ড চেক করুন।");
        setDataPresent(false);
        reload();
      } else if (pos.status === 403) {
        ToastFailedMsg("জমা হয়নি। Username does Not Exist!!! Logout first, then Login again or Register!!!", 2000);
        setTimeout(() => window.location.reload(), 2000);
      }
      else {
        ToastFailedMsg("জমা হয়নি");
        setTimeout(() => window.location.reload(), 2000);
      }
    }
  };
  const test = async () => {
    if (!showSubmitSpinner) {
      //console.log("kaj kore?", detail);
      //console.log(rdd.isAndroid, rdd);
      setShowSubmitSpinner(true);
      if (getLoggedInUserId() === undefined) {
        setShow(true);
        return;
      }


      let speaker_annotation: any = new FormData();
      speaker_annotation.append("data", JSON.stringify(data));
      speaker_annotation.append("details", JSON.stringify(detail));

      console.log("Data ", JSON.stringify(data));
      // let pos = await postAudio(speaker_annotation);

      // if (pos.status === 200) {
      //   ToastSuccessMsg("ডেটা প্রসেসিং চলছে। আপডেট জানতে কিছুক্ষণ পর ড্যাশবোর্ড চেক করুন।");
      //   setDataPresent(false);
      //   reload();
      // } else {
      //   ToastFailedMsg("জমা হয়নি");
      //   setTimeout(() => window.location.reload(), 2000);
      // }
    }
  };

  const yesnoClick = (event: any, yes_no_reason: any) => {
    getDetails();//can assign reason here by passing parameter in getDetails
    setComponent([
      ...component,
      <div>
        {event} {checkedCount}
      </div>,
    ]);
    setCheckedCount(checkedCount + 1);
    console.log(count);
    if (count >= 0 && count <= 4) {
      setDataPresent(true);
    }
    let value;
    console.log("reason val: ", yes_no_reason);
    console.log("event: ", event);


    if (event === "YES") {
      value = 1;
    } else {
      value = -1;
    }

    let audio = "audio_" + audioSubmitNumber;
    let vote = "vote_" + audioSubmitNumber;
    let duration = "duration_" + audioSubmitNumber;
    let reason = "reason_" + audioSubmitNumber;
    setData({
      ...data,
      [audio]: audioId[count],
      [vote]: value,
      [duration]: audioTime[count],
      [reason]: yes_no_reason,//set reason here
    });

    setAudioSubmitNumber(audioSubmitNumber + 1);

    if (audioSubmitNumber + 1 === 6) {
      setDisableListen(true);
      setListenOption(2);
    }

    skipAudio();
  };

  const skipAudio = () => {
    if (count + 1 >= scriptDescription.length) {
      console.log("getAudioWithScripts called from 537");
      getAudioWithScripts();
      setIsLoading(false);
    }
    setCount(count + 1);
    setYesNoButton(true);
    setShowReasonDropDown(false);
    setAudioCurrentTime(0);
  };

  let [showBlur, setShowBlur]: any = useState(false);
  let [apiHitStartTime, setApiHitStartTime]: any = useState(null);
  let [apiResponded, setApiResponded]: any = useState(false);
  let [networkError, setNetworkError]: any = useState(false);

  useEffect(() => {
    // console.log("test",apiHitStartTime, Date.now(), apiResponded);
    if (apiHitStartTime != null && Date.now() - apiHitStartTime > 2000 && !apiResponded) {
      setNetworkError(true);
    }
  },)

  useEffect(() => {
    if (networkError) {
      ToastWarningMsg("ইন্টারনেটের গতি দুর্বল। অনুগ্রহপূর্বক অপেক্ষা করুন।");
    }
  }, [networkError])

  const fetchAudio = (arg: any) => {
    let extension = arg["voiceUrl"].split(".").slice(-1)[0];
    getShununAudio(arg)
      .then(response => response.json())
      .then(json => {
        // console.log("Response JSON:", json.data);
        setAudio(new Audio(`data:audio/${extension};base64,${window.btoa(json.data)}`))
        setApiResponded(true);
        setNetworkError(false);
      }
      ).catch(err => {
        console.log(err);
        fetchAudio(arg);
      }
      );
  }

  const playAudio = () => {
    setApiResponded(false);
    setShowBlur(true);
    setYesNoButton(true);
    setIsPlaying(true);
    setShowReasonDropDown(false);
    setApiHitStartTime(Date.now());
    let requestArg = { "voiceUrl": audioUrls[count] }
    // console.log(requestArg);
    fetchAudio(requestArg);
    // getShununAudio(requestArg)
    //   .then(response => response.json())
    //   .then(json => {
    //     // console.log("Response JSON:", json.data);
    //     setAudio(new Audio(`data:audio/wav;base64,${window.btoa(json.data)}`))
    //     setApiResponded(true);
    //     setNetworkError(false);
    //   }
    //   ).catch(err => console.log(err)
    //   );
  };

  const stopAudio = () => {
    console.log("audio: ", audio);

    audio.pause();
    afterAudioPlay();
  };

  const afterAudioPlay = () => {
    setYesNoButton(false);
    setIsPlaying(false);
    setShowReasonDropDown(true);
    handleClick();
  };
  const [dataPresent, setDataPresent]: any = useState(false);

  let [audioCurrentTime, setAudioCurrentTime]: any = useState(0);
  let [audioDuration, setAudioDuration]: any = useState(100);

  useEffect(() => {
    if (audio != null) {
      setShowBlur(false);
      setAudioCurrentTime(0);
      audio.addEventListener('timeupdate', (event: any) => {
        const currentTime = audio.currentTime;
        console.log(currentTime);
        setAudioCurrentTime(currentTime);
        // const duration = audio.duration;
        const duration = audioTime[count];
        setAudioDuration(duration);
      }, false);
      audio.play();
      audio.addEventListener("ended", () => afterAudioPlay());
    }
  }, [audio]);

  useEffect(() => {
    // the handler for actually showing the prompt
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };

    // if the form is NOT unchanged, then set the onbeforeunload
    if (dataPresent) {
      window.addEventListener("beforeunload", handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    // since this is not dirty, don't do anything
    return () => { };
  }, [dataPresent]);

  const [playState1, setPlayState1]: any = useState('clickimglisten')
  const [playState2, setPlayState2]: any = useState('clickimglistenplay')

  const toggolePlayPauseClick = (val: any) => {

    if (val === 3) {
      //   setPlayState1(!btnState? "clickstoplisten" : "clickimglisten")
      //   setPlayState2(!btnState? "clickimglistenplay": "clickstoplistenplay")
    } else {
      val === 1 ?
        setPlayState1(!btnState ? "clickstoplisten" : "clickimglisten") :
        setPlayState2(!btnState ? "clickimglistenplay" : "clickstoplistenplay")

      //   // setPlayState(val == 1 ? "clickimglistenplay" : "clickimglisten");
      //   // setStopState(val == 2 ? "clickstoplistenplay" : "clickstoplisten");
    }
  }

  const toggoleBtnState = (val = 3) => {
    if (getLoggedInUserId() === undefined) {
      ToastWarningMsg("নিবন্ধন বা লগইন করুন।");
    }
    else {
      setBtnState(!btnState);
      toggolePlayPauseClick(val);

      console.log("btn state: ", btnState)
      console.log("playState1: ", playState1);
      console.log("playState2: ", playState2);


      if (playState1 === 'clickimglisten' || playState2 === "clickimglistenplay") {
        playAudio();
      } else {
        stopAudio();
      }
    }
  }

  // const toggoleBtnState = (event : React.MouseEvent, val:any):void => {
  //   event.preventDefault();
  //   setBtnState(!btnState);
  //   toggolePlayPauseClick(val);

  //   console.log("btn state: ", btnState)
  //   console.log("playState1: ", playState1);
  //   console.log("playState2: ", playState2);


  //   if (playState1 === 'clickimglisten' || playState2 === "clickimglistenplay"){
  //     playAudio();
  //   }else {
  //     stopAudio();
  //   }
  // }
  const onShowDropDown = () => {
    setReasonShow("show");
  }



  return (
    <>
      {/* <LongRunningLoading isLoading={pageLoader} /> */}
      {openModal && <Modal closeModal={setOpenModal} />}
      <div className="bodycontent">
        <div className="bodymenuitembolun">
          <div className="boluncotopokothon">
            <div className="homeread homereadmobile">
              <div className="bolunsununmobile">
                <span className="patharrowmobile">
                  <a href="/"><img src={mobilehome} alt="patharrow" /></a>
                  <a href="/"><span className="patharrowmobile">মূলপাতা</span></a>
                </span>
              </div>
              <div className="readingrulemobile">
                <div className="rruleitem">
                  <ul>
                    <li>
                      {/* <a href="/reading-rules" className="readrule">
                        পড়ার নিয়মাবলি
                      </a> */}
                      <a href="/listening-rules" className="readrule">
                        যাচাইয়ের শর্তাবলি&nbsp;
                        <img src={readrule} alt="readrule" />
                      </a>
                      {/* <img src={readrule} alt="readrule" /> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bolunsunun">
              <span className="patharrow">
                <a href="/"><img src={patharrow} alt="patharrow" /></a>
                <span className="homearrow">মূলপাতা</span>
              </span>
              {/* <div className="bolonsununitem">
                <ul className="tab-list">
                  <div className="bolunactive">
                    <li
                      className={`tabs ${getActiveClass(
                        1,
                        "active-tabs"
                      )} sununbtn`}
                      onClick={() => toggleTab(1)}>
                      <span>
                        <img src={bolun} alt="" />
                      </span>
                    </li>
                    <span>
                      <h6 className="boluntitle">বলুন</h6>
                    </span>
                  </div>

                  <div className="sununactive">
                    <li
                      className={`tabs ${getActiveClass(
                        2,
                        "active-tabs"
                      )} sununbtn`}
                      onClick={() => toggleTab(2)}>
                      <span>
                        <img src={sunun} alt="sunun" />
                      </span>
                    </li>
                    <span>
                      <h6 className="boluntitle">শুনুন</h6>
                    </span>
                  </div>
                </ul>
              </div> */}
            </div>

            <div className="conversation">
              {/* <div className="conitem">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist">
                  {show ? (
                    <div className="cothopokothongroup">
                      <li className="nav-item" role="presentation">
                        <button
                          //   className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                          onClick={handleClickclose}
                          className={` btnkotopokothon tabnoborder afterclick `}>
                          কথোপকথন
                          <span
                            className={` closeditem ${toggleClassCheckclose}`}
                            onClick={handleClickclose}
                            onClick={() => {
                              setOpenModal(true);
                            }}>
                            X
                          </span>
                        </button>
                      </li>

                      <div className={` newbtntab ${toggleClassCheckclose}`}>
                        <div className="formobile">
                          <li className="nav-item" role="presentation">
                            <div class="dropdown">
                              <button
                                class="btnakok btn btn-secondary dropdown-toggle nav-link active"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                                একক
                              </button>
                              <ul class="dropdown-menu nodesktop">
                                <li>
                                  <a
                                    id="pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                    class="dropdown-item dsingleitem itemactive"
                                    href="#">
                                    <span>একক</span>
                                    <span>
                                      <img src={check} alt={check} />
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item dsingleitem"
                                    href="#"
                                    id="pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false">
                                    দ্বৈত
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item dsingleitem"
                                    href="#"
                                    id="pills-contact-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-contact"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-contact"
                                    aria-selected="false">
                                    ততোধিক
                                  </a>
                                </li>
                              </ul>
                            </div> */}

              {/* <button
                              className="btnakok nav-link active dropdown-toggle"
                              id="menu1"
                              data-toggle="dropdown">
                              একক <span class="caret"></span>
                            </button> */}
              {/* </li>
                        </div>
                        <div className="fordesktop">
                          <li className="nav-item" role="presentation">
                            <button
                              className="btnakok nav-link active "
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true">
                              একক
                            </button>
                          </li>

                          <li className="nav-item" role="presentation">
                            <button
                              className=" btndoito nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false">
                              দ্বৈত
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className=" btntoito nav-link"
                              id="pills-contact-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-contact"
                              type="button"
                              role="tab"
                              aria-controls="pills-contact"
                              aria-selected="false">
                              ততোধিক
                            </button>
                          </li>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <li className="nav-item test" role="presentation">
                      <button
                        className="afterclick tabnoborder btnkotopokothon"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => setShow(!show)}>
                        কথোপকথন
                      </button>
                    </li>
                  )}

                  {!showlacture ? (
                    <buttton
                      className="lacture"
                      onClick={() => setShowlacture(!showlacture)}>
                      <span className={` lecture`}>লেকচার</span>
                    </buttton>
                  ) : (
                    <span className="lacture">
                      <buttton
                        onClick={() => setShowlacture(!showlacture)}
                        className={` lecture ${lectureactive}`}>
                        লেকচার
                        <button
                          className="closeditemactive"
                          onClick={() => {
                            setOpenModal(true);
                          }}>
                          X
                        </button>
                      </buttton>
                    </span>
                  )}
                </ul>
              </div> */}
            </div>
          </div>
          <div className="readingrule">
            <div className="rruleitem">
              <ul>
                <li>
                  <a href="/listening-rules" className="readrule">
                    যাচাইয়ের শর্তাবলি&nbsp;
                    <img src={readrule} alt="readrule" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="content-container innerbodyitem">
          <div className="mainrontainer">
            <div className="textbar" style={showBlur ? { opacity: "20%" } : {}}>

              <div className={` overallcontent kotopokothon`}>
                <div
                  className={`content ${getActiveClass(
                    1,
                    "active-content"
                  )} boluncontent`}>
                  <div className='container-fluid' style={{ display: "flex", justifyContent: 'space-between', border: "0px solid red", position: "absolute", top: "15px" }}>
                    <div>
                      <Timer expiryTimestamp={time} dataPresent={dataPresent} setDataPresent={setDataPresent} />
                    </div>

                    <div className="agiyejannew">
                      {
                        (isPlaying || audioSubmitable || audioSubmitNumber === 6) ? (
                          null
                        ) : (
                          <button className="agiyejan" onClick={skipAudio}>
                            এড়িয়ে যান
                            <span className="agiyespan">
                              <img src={agiyejan} alt="agiyejan" />
                            </span>
                          </button>
                        )
                      }
                    </div>
                  </div>
                  <div className="bodycontentitem">
                    {showBlur ? <Spinner animation="border" variant="dark" style={{ opacity: "100%" }} /> : null}
                    {
                      disableListen ? (
                        <p className='bodytextcontent' style={{ color: "red" }}> যাচাইকৃত অডিও সমূহ জমা দিন</p>
                      ) : !speechAreAvailable ? (
                        <p className='bodytextcontent' style={{ color: "red" }}>এই মুহূর্তে যথেষ্ট অডিও নেই।</p>
                      ) :
                        scriptDescription[count] !== null ? (
                          <p className="bodytextcontent">{scriptDescription[count]}</p>
                        ) :
                          <p className='bodytextcontent' style={{ color: "red" }}>এই মুহূর্তে যথেষ্ট অডিও নেই।</p>
                    }
                  </div>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress
                      variant="determinate"
                      value={(audioCurrentTime * 100) / audioDuration}
                    />
                  </Box>
                </div>
              </div>
            </div>

            <div className="clickbar clickbarlistencom">
              <div className="clickbaritem">
                <div className={`${toggleClassChecklisten}`}>
                  <div className="habtnarea">
                    {
                      showReasonDropDown ? (
                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle btnhatick show"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="true">
                            <span className="hatick">
                              <img src={hatick} alt={hatick} />
                            </span>
                            <span className="hasunun">হ্যাঁ</span>
                          </button>

                          <ul
                            className="dropdown-menu hanacontent show haleft"
                            aria-labelledby="dropdownMenuButton1">
                            {
                              yes_reasons.map(
                                reason => {
                                  return <li key={reason}>
                                    <a className="dropdown-item hanaitem" href="#" onClick={() => { yesnoClick("YES", reason) }}>
                                      - {reason}
                                    </a>
                                  </li>
                                }
                              )
                            }
                          </ul>
                        </div>
                      ) : (
                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle btnhatick"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="true"
                            disabled>
                            <span className="hatick">
                              <img src={hatick} alt={hatick} />
                            </span>
                            <span className="hasunun">হ্যাঁ</span>
                          </button>
                        </div>
                      )
                    }

                  </div>
                  {
                    listenOption === 1 ? (
                      component[0] ? (
                        <button
                          type="button"
                          className="clickpoint"
                          onClick={() => toggoleBtnState(1)}
                          disabled={isPlaying}
                        >
                          <img src={clickpoint} alt="" />
                        </button>
                      ) : (
                        <div>
                          <button
                            type="button"
                            className="clickpoint"
                            onClick={() => toggoleBtnState(1)}
                            disabled={isPlaying}>
                            <img src={clickpoint} alt="" />
                          </button>
                          <span className="tooltiptextlisten">
                            রেকর্ড শুনতে ক্লিক করুন।
                          </span>
                        </div>
                      )
                    ) : (
                      <button
                        type="button"
                        className="clickpoint clickpointplay"
                        onClick={() => toggoleBtnState(2)}
                        disabled={isPlaying || disableListen || !speechAreAvailable || count >= scriptDescription.length}
                      >
                        <img src={clickpoint} alt="" />
                      </button>
                    )
                  }
                  <div className="habtnarea">
                    {
                      showReasonDropDown ? (
                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle btnnatick show"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <span className="natick">
                              <img src={nathick} alt={nathick} />
                            </span>
                            <span className="nasunun">না</span>
                          </button>

                          <ul
                            className="dropdown-menu hanacontent show"
                            aria-labelledby="dropdownMenuButton1">
                            {
                              no_reasons.map(
                                reason => {
                                  return <li key={reason}>
                                    <a className="dropdown-item hanaitem" href="#" onClick={() => { yesnoClick("NO", reason) }}>
                                      - {reason}
                                    </a>
                                  </li>
                                }
                              )
                            }
                          </ul>
                        </div>
                      ) : (
                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle btnnatick"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            disabled>
                            <span className="natick">
                              <img src={nathick} alt={nathick} />
                            </span>
                            <span className="nasunun">না</span>
                          </button>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rightsidebarwithseelct listenrighttop">
            <div className="rightsidebar">
              <div className="mobilerightsidebar">
                <ul>
                  {
                    component[0] ? (
                      <li className="activenopading comminrecord">
                        <span className="countitem recordactive">1</span>
                      </li>
                    ) : (
                      <li className="comminrecord">
                        <span className="countitem">1</span>
                        <span className="recordstarthiden">রেকর্ডিং শুরু করুন</ span>
                      </li>
                    )
                  }
                  {
                    component[1] ? (
                      <li className="activenopading comminrecord">
                        <span className="countitem recordactive">2</span>
                      </li>
                    ) : component[0] ? (
                      <li className="comminrecord">
                        <span className="countitem">2</span>
                        <span className="recordstarthiden">রেকর্ডিং শুরু করুন</span>
                      </li>
                    ) : (
                      <li className="rinactive comminrecord">
                        <span className="countitem">2</span>
                      </li>
                    )
                  }
                  {
                    component[2] ? (
                      <li className="activenopading comminrecord">
                        <span className="countitem recordactive">3</span>
                      </li>
                    ) : component[1] ? (
                      <li className="comminrecord">
                        <span className="countitem">3</span>
                        <span className="recordstarthiden">রেকর্ডিং শুরু করুন</span>
                      </li>
                    ) : (
                      <li className="rinactive comminrecord">
                        <span className="countitem">3</span>
                      </li>
                    )
                  }
                  {
                    component[3] ? (
                      <li className="activenopading comminrecord">
                        <span className="countitem recordactive">4</span>
                      </li>
                    ) : component[2] ? (
                      <li className="comminrecord">
                        <span className="countitem">4</span>
                        <span className="recordstarthiden">রেকর্ডিং শুরু করুন</span>
                      </li>
                    ) : (
                      <li className="rinactive comminrecord">
                        <span className="countitem">4</span>
                      </li>
                    )
                  }
                  {
                    component[4] ? (
                      <li className="activenopading comminrecord">
                        <span className="countitem recordactive">5</span>
                      </li>
                    ) : component[3] ? (
                      <li className="comminrecord">
                        <span className="countitem">5</span>
                        <span className="recordstarthiden">রেকর্ডিং শুরু করুন</span>
                      </li>
                    ) : (
                      <li className="rinactive comminrecord">
                        <span className="countitem">5</span>
                      </li>
                    )
                  }
                </ul>
              </div>
              <div className="rightsidebarcontent nomobile">
                {
                  component[0] ? (
                    <div className="singleitem listenborder">
                      <ul>
                        <li>
                          <span className="playstart">
                            <img src={listenspeakeractive} alt="porasunte" />
                          </span>
                        </li>

                        <li className="clisten clistenactive">
                          <span className="countitem ">1</span>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="singleitem listenborder">
                      <ul>
                        <li>
                          <span className="playstart">
                            <img src={listenspeaker} alt="porasunte" />
                          </span>
                        </li>

                        <li className="clisten">
                          <span className="countitem ">1</span>
                        </li>
                      </ul>
                    </div>
                  )
                }
                {
                  component[1] ? (
                    <div className="singleitem listenborder">
                      <ul>
                        <li>
                          <span className="playstart">
                            <img src={listenspeakeractive} alt="porasunte" />
                          </span>
                        </li>

                        <li className="clisten clistenactive">
                          <span className="countitem ">2</span>
                        </li>
                      </ul>
                    </div>
                  ) : component[0] ? (
                    <div className="singleitem listenborder">
                      <ul>
                        <li>
                          <span className="playstart">
                            <img src={listenspeaker} alt="porasunte" />
                          </span>
                        </li>

                        <li className="clisten">
                          <span className="countitem ">2</span>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="singleitemlisten onlyitem ">
                      <ul>
                        {/* <li> <span className='recordstart'>রেকর্ডিং শুরু করুন</span> </li> */}
                        {/* <li></li> */}
                        <li>
                          <span className="countitem">2</span>
                        </li>
                      </ul>
                    </div>
                  )
                }
                {
                  component[2] ? (
                    <div className="singleitem listenborder">
                      <ul>
                        <li>
                          <span className="playstart">
                            <img src={listenspeakeractive} alt="porasunte" />
                          </span>
                        </li>

                        <li className="clisten clistenactive">
                          <span className="countitem ">3</span>
                        </li>
                      </ul>
                    </div>
                  ) : component[1] ? (
                    <div className="singleitem listenborder">
                      <ul>
                        <li>
                          <span className="playstart">
                            <img src={listenspeaker} alt="porasunte" />
                          </span>
                        </li>

                        <li className="clisten">
                          <span className="countitem ">3</span>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="singleitemlisten onlyitem ">
                      <ul>
                        {/* <li> <span className='recordstart'>রেকর্ডিং শুরু করুন</span> </li> */}
                        {/* <li></li> */}
                        <li>
                          <span className="countitem">3</span>
                        </li>
                      </ul>
                    </div>
                  )
                }
                {
                  component[3] ? (
                    <div className="singleitem listenborder">
                      <ul>
                        <li>
                          <span className="playstart">
                            <img src={listenspeakeractive} alt="porasunte" />
                          </span>
                        </li>

                        <li className="clisten clistenactive">
                          <span className="countitem ">4</span>
                        </li>
                      </ul>
                    </div>
                  ) : component[2] ? (
                    <div className="singleitem listenborder">
                      <ul>
                        <li>
                          <span className="playstart">
                            <img src={listenspeaker} alt="porasunte" />
                          </span>
                        </li>

                        <li className="clisten">
                          <span className="countitem ">4</span>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="singleitemlisten onlyitem ">
                      <ul>
                        {/* <li> <span className='recordstart'>রেকর্ডিং শুরু করুন</span> </li> */}
                        {/* <li></li> */}
                        <li>
                          <span className="countitem">4</span>
                        </li>
                      </ul>
                    </div>
                  )
                }
                {
                  component[4] ? (
                    <div className="singleitem listenborder">
                      <ul>
                        <li>
                          <span className="playstart">
                            <img src={listenspeakeractive} alt="porasunte" />
                          </span>
                        </li>

                        <li className="clisten clistenactive">
                          <span className="countitem ">5</span>
                        </li>
                      </ul>
                    </div>
                  ) : component[3] ? (
                    <div className="singleitem listenborder">
                      <ul>
                        <li>
                          <span className="playstart">
                            <img src={listenspeaker} alt="porasunte" />
                          </span>
                        </li>

                        <li className="clisten">
                          <span className="countitem ">5</span>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="singleitemlisten onlyitem ">
                      <ul>
                        {/* <li> <span className='recordstart'>রেকর্ডিং শুরু করুন</span> </li> */}
                        {/* <li></li> */}
                        <li>
                          <span className="countitem">5</span>
                        </li>
                      </ul>
                    </div>
                  )
                }
              </div>
            </div>

            <div className="selectarea">
              {/* <div className="selectarea">
                <select
                  name="recordingarea"
                  required=""
                  className="selectcontroll">
                  <option> রেকর্ডিং এলাকা রুমের মধ্যে</option>
                  <option> রেকর্ডিং এলাকা রুমের বাইরে</option>
                  <option> রেকর্ডিং এলাকা মাঠে</option>
                  <option> রেকর্ডিং এলাকা মার্কেট</option>
                  <option> রেকর্ডিং এলাকা রোড</option>
                </select>
              </div> */}
              <div className="buttonaea">
                {
                  // component[4] ? (
                  //   <button className="btnjomadin btnactive" onClick={onSubmit}>{
                  //     showSubmitSpinner ?
                  //       (<Spinner animation="border" variant="light" />) :
                  //       (<span className='disjomadin'> <img src={jomadin} alt="jomadin" /> জমা দিন</span>)
                  //   }
                  //   </button>
                  // ) : (
                  <button className="btnjomadin jomadinsunun">
                    <span className="disjomadin dislisten">
                      <img src={disjomadin} alt="jomadin" />
                    </span>
                    জমা দিন
                  </button>
                  // )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
