import React, { useState, useEffect, useRef } from 'react';

// import "./bodycontent.css";
import "./tabconversation.css";
import bolun from "./images/stts/bolun.png"
import bolunbottom from "./images/stts/bolunbottom.png"
import sunun from "./images/stts/sunun_icon.png"
import readrule from "./images/stts/readrule.png"
import agiyejan from "./images/stts/agiyejan.png"
import clickpoint from "./images/stts/clickhover.png"
import porasunte from "./images/stts/porasunte.png"
import pauseicon from "./images/stts/pause icon.png";
import mobilehome from "./images/stts/home.png";

import reload from "./images/stts/reload.png"
import disjomadin from "./images/stts/disjomadin.png"
import jomadin from "./images/stts/jomadin.png"
import Modal from './assets/js/Modal'
import patharrow from "./images/stts/patharrow.png";
import LongRunningLoading from '../../common/LongRunningLoading';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useRecorder from '../../common/AudioRecorder';
import { getLocalRecordingArea, getLoggedInUserId, setLocalRecordingArea } from '../../common/http';
import { getCrowdScripts, getUserPermissionFlag } from './crowd_source.service';
import { ToastFailedMsg, ToastWarningMsg } from '../../common/toast';
import { useGeolocated } from 'react-geolocated';
import clickhover from "./images/stts/clickhover.png";
import check from "./images/stts/check.png";
import * as rdd from 'react-device-detect';


import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Carousel } from 'react-bootstrap';

import Timer from "./Timer";
import Spinner from 'react-bootstrap/Spinner';
import { type } from 'os';





export default function BolunComponent(props: any) {
  const [id, setId]: any = useState();
  const [ToggleState, setToggleState] = useState(1);
  const [isTimerTrigger, setIsTimerTrigger]: any = useState(false);
  const toggleTab = (index: any) => {
    setToggleState(index);
  };

  const getActiveClass = (index: any, className: any) =>
    ToggleState === index ? className : "";

  // play stop button show hide

  const [btnState, setBtnState] = useState(false);

  let toggleClassCheck = btnState ? "clickstop" : "clickimg";

  // Play stop end here

  // const [btnStatejomadin, setBtnStatejomadin] = useState(false)

  // function handleClickjomadin(){
  //   setBtnStatejomadin(btnStatejomadin => !btnStatejomadin);
  // }

  // let toggleClassCheckjomadin = btnStatejomadin? 'clickstop': 'btnactive';

  // speaking area show hide

  const [show, setShow] = useState(false);
  // const [showclose, setShowclose] = useState(false);
  // speaking area show hide end

  // Lacture button active

  const [showlacture, setShowlacture] = useState(false);

  let lectureactive = showlacture ? "lectureactive" : "";

  // Show doito and toito
  const [showdoito, setShowdoito] = useState(false);

  let showdoitoactive = showdoito ? "doitotoactive" : "";
  // Show doito and toito
  const [showtoito, setShowtoito] = useState(false);

  let showtoitoactive = showtoito ? "doitotoactive" : "";

  // Show doito and toito
  // Lacture button active
  const [btnStateclose, setBtnStateclose] = useState(true);

  // Lacture button inactive

  function handleClickclose() {
    setBtnStateclose((btnStateclose) => !btnStateclose);
  }

  let toggleClassCheckclose = btnStateclose
    ? " kotopokothon "
    : " cothopokothongroupnone";
  // let toggleClassCheckcloseshow = btnStateclose? '  ': ' cothopokothongroupbtn';
  let kbtactive = btnStateclose ? "kbtactive" : "afterclick";
  let divcontenthide = btnStateclose ? "overallhide" : "overallcontent";

  // =============Modal State start here ==========================

  const [openModal, setOpenModal] = useState(false);

  // =============Modal State start here ==========================
  // ===================== Recorder ===========

  let [pageLoader, setPageLoader]: any = useState(true);
  const navigate = useNavigate();
  let [recorderPermission, setRecorderPermission]: any = useState(false);
  const ref = useRef(null);
  const [isTooltipShow, setIsToolTipShow]: any = useState(true);
  let [startTime, setStartTime]: any = useState(Date.now());
  let [
    audioURL,
    setAudioURL,
    isRecording,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    uploadAudio,
    checkRecording,
    count,
    audios,
    reRecordingState,
    setRecordingState,
    reRecordingNumber,
    setRerecordingNumber,
    setScriptId,
    scriptId,
  ]: any = useRecorder(props = { type: 0 });
  let [recordedScripts, setRecordedScripts]: any = useState([]);
  let [recordedScriptsDetails, setRecordedScriptsDetails]: any = useState([]);
  const [startRecord, setstartRecord]: any = useState(true);
  let [audioCount, setAudioCount]: any = useState(false);
  let [audio, setAudio]: any = useState(null);
  let [playButtonDisable, setPlayButtonDisable]: any = useState(false);
  let [speake, setSpeake]: any = useState(true);
  let [submitState, setSubmitState]: any = useState(false);
  let [speakerId, setSpeakerId]: any = useState(null);
  let [showConversation, setShowConversation]: any = useState(false);
  let [recordingArea, setRecordingArea]: any = useState();
  // let [recordingAreaEmpty, setRecordingAreaEmpty]: any = useState(true);

  const [formData, setFormData] = useState({
    distance: "",
    recordingArea: "",
    recordingDate: "",
    recordingPlace: "",
    comments: "",
    id: "",
  });
  const [index, setIndex]: any = useState(0);
  let [script, setScript]: any = useState([{}]);
  let [scriptIndex, setScriptIndex]: any = useState([]);
  let [audioNumber, setAudioNumber]: any = useState("");
  // const area = ["--রেকর্ডিং এলাকা নির্বাচন করুন--", "ঘরের ভেতরে", "ঘরের বাইরে", "বাজারে", "পথে", "উন্মুক্ত স্থানে", "অন্যান্য জনবহুল স্থানে"];
  const recordingAreas = [
    // "রুমের মধ্যে",
    // "রুমের বাইরে",
    // "মাঠে",
    // "মার্কেট",
    // "রোড",
    // "উন্মুক্ত স্থান",
    // "অন্যান্য জনবহুল স্থান"
    "রুমের মধ্যে",
    "রুমের বাইরে",
    "মার্কেটে",
    "মাঠে",
    "রাস্তায়",
    "উন্মুক্ত স্থানে",
    "অন্যান্য জনবহুল স্থানে"
  ]

  const [playPauseState1, setPlayPauseState1]: any = useState(false);
  const [playPauseState2, setPlayPauseState2]: any = useState(false);
  const [playPauseState3, setPlayPauseState3]: any = useState(false);
  const [playPauseState4, setPlayPauseState4]: any = useState(false);
  const [playPauseState5, setPlayPauseState5]: any = useState(false);
  const [playPauseAudioName, setPlayPauseAudioName]: any = useState("");
  const [checkForFiveAudios, setCheckForFiveAudios]: any = useState(true);

  useEffect(() => {
    setRecordingArea(getLocalRecordingArea());
    console.log("Speaker Id " + getLoggedInUserId());
    setSpeakerId(getLoggedInUserId());
    console.log("getScripts called from 195");
    getScripts();

    console.log("local recording area: ", getLocalRecordingArea())
    console.log("recording area: ", recordingArea)

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        if (count > 0 && !recorderPermission) {
          window.location.reload();
        }
        setRecorderPermission(true);
        console.log("MIC Found");
      })
      .catch(function (err) {
        setRecorderPermission(false);
        ToastWarningMsg("Check your microphone permission");
        console.log("No mic for you!");
      });

    localStorage.removeItem('latest_duration');
    localStorage.removeItem('latest_word_count');
    localStorage.removeItem('latest_syllable_count');
    localStorage.removeItem('latest_phoneme_count');
    localStorage.removeItem('recorded_scripts_length');

    if (props.id) {
      setId(props.id);
      getActiveClass(1, "active-tabs");
      toggleTab(1)
    }
    const interval = setInterval(() => setShowHelp(false), 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    console.log("recording area: ", recordingArea);
    setLocalRecordingArea(recordingArea);
  }, [recordingArea]);

  const recorder = () => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((stream) => {
        // @ts-ignore
        window.localStream = stream; // A
        // @ts-ignore
        window.localAudio.srcObject = stream; // B
        // @ts-ignore
        window.localAudio.autoplay = true; // C
      })
      .catch((err) => {
        console.log("u got an error:" + err);
      });
  };


  const [noScriptFound, setNoScriptFound]: any = useState(false);
  const getScripts = async () => {
    if (getLoggedInUserId() === undefined || getLoggedInUserId() === null) {
      console.log("hello world");
      console.log("Id ", speakerId);

      let res = await getCrowdScripts(getLoggedInUserId());

      if (res.status === 200 || res.status === 201) {
        // setIsLoading(false);
        try {
          let { data } = await res.json();
          console.log("Crowd Script ", { data });
          setPageLoader(false);

          data.forEach((item: any) => {
            console.log("Description", item.description);
            setScript([...script, { description: item.description }]);
          });

          setScript(data);
          console.log(data);
        }
        catch (err) {
          console.log(err);
          setNoScriptFound(true);
        }
      }
      else {
        setNoScriptFound(true);
      }
    } else {
      try {
        console.log("Id ", getLoggedInUserId());
        let res = await getCrowdScripts(getLoggedInUserId());

        if (res.status === 200 || res.status === 201) {
          let { data } = await res.json();
          console.log("Crowd Script ", { data });
          setPageLoader(false);

          data.forEach((item: any) => {
            console.log("Description", item.description);
            setScript([...script, { description: item.description }]);
          });

          setScript(data);

          console.log(data);
        }
        else {
          setNoScriptFound(true);
        }
      }
      catch (err) {
        console.log(err);
        setNoScriptFound(true);
      }
    }
    setShowNull(false);
  };


  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // const onFormSubmit = (event: any) => {
  //   event.preventDefault();
  //   console.log("URL ", audioURL);

  //   if (audioURL === "") {
  //     ToastWarningMsg("Record your voice First");
  //   } else {
  //     formData.id = script[index].id;
  //     console.log("ID ", script[index].id);
  //     uploadAudio(formData);
  //   }
  // };

  const [dataPresent, setDataPresent]: any = useState(false);



  const handler = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = "";
  };
  useEffect(() => {
    // the handler for actually showing the prompt
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload

    // if the form is NOT unchanged, then set the onbeforeunload
    if (dataPresent) {
      window.addEventListener("beforeunload", handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    // since this is not dirty, don't do anything
    return () => { };
  }, [dataPresent]);

  useEffect(() => {
    console.log("recorded-audio file: ", audios);
  }, [audios]);

  const [disabled, setDisabled]: any = useState(false);
  const [showSubmitSpinner, setShowSubmitSpinner]: any = useState(false);
  const onSubmit = async () => {
    if (!showSubmitSpinner) {
      // setSubmitState(true);

      // if(audios.audio1 !== ""){
      //     console.log("Code entered here");

      //     console.log(audios.audio1);

      //     uploadAudio(formData);
      // }else{
      //     console.log("++++")
      // }
      console.log(rdd.isAndroid, rdd);
      let useragent: any = {};
      let geoData: any = {};
      let deviceString: any = {};


      // useragent.isMobile = rdd.isMobile;
      // useragent.isAndroid = rdd.isAndroid;
      // useragent.isIOS = rdd.isIOS;
      // useragent.isIOS13 = rdd.isIOS13;   
      //useragent.isWindows = rdd.isWindows;
      //useragent.isMacOs = rdd.isMacOs;
      // useragent.isChrome = rdd.isChrome;
      // useragent.isFirefox = rdd.isFirefox;
      // useragent.isEdge = rdd.isEdge;
      // useragent.isSamsungBrowser=rdd.isSamsungBrowser;
      // useragent.isBrowser = rdd.isBrowser;
      useragent.isDesktop = rdd.isDesktop;
      useragent.mobileVendor = rdd.mobileVendor;
      useragent.mobileModel = rdd.mobileModel;
      useragent.osName = rdd.osName;
      useragent.osVersion = rdd.osVersion;
      useragent.browserName = rdd.browserName;
      useragent.fullBrowserVersion = rdd.fullBrowserVersion;

      //let useragentString= JSON.stringify(useragent);
      //console.log(useragentString);

      let geoDataString: any;

      if (!isGeolocationAvailable) {
        console.log("Your browser does not support Geolocation");
      } else if (!isGeolocationEnabled) {
        console.log("Geolocation is not enabled");
      } else {
        console.log("Latitude !!!", coords?.latitude);

        geoData.latitude = coords?.latitude;
        geoData.longitude = coords?.longitude;
        geoData.altitude = coords?.altitude;
        geoData.heading = coords?.heading;
        geoData.speed = coords?.speed;

        //geoDataString=JSON.stringify(geoData);
        //console.log(geoDataString);
      }
      // const { coords, isGeolocationAvailable, isGeolocationEnabled } =
      //   useGeolocated({
      //     positionOptions: {
      //       enableHighAccuracy: true,
      //     },
      //     userDecisionTimeout: 5000,
      //   });

      let detailString: any = {};
      if (!navigator.mediaDevices?.enumerateDevices) {
        console.log("enumerateDevices() not supported.");
      } else {
        let flag = 0;
        // List cameras and microphones.
        navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            devices.forEach((device, index, arr) => {
              //console.log(device);


              if (!(device.deviceId === "default" || device.deviceId === "communications") && (device.kind === "audioinput")) {
                console.log("i want this device", device);

                deviceString[device.kind] = device.label;
                //console.log("json",JSON.stringify(deviceString))


                detailString.userAgent = useragent;
                detailString.geoLocation = geoData;
                detailString.device = deviceString;
                console.log("detailString", JSON.stringify(detailString));

                // if (checkForFiveAudios || recordingAreaEmpty) {
                //   //show what to do in toast
                //   if (checkForFiveAudios) {
                //     ToastFailedMsg("৫টি অডিও রেকর্ড করুন");
                //   }
                //   else if (recordingAreaEmpty) {
                //     ToastFailedMsg("রেকর্ডিং এলাকা নির্বাচন করুন");
                //   }
                //   return;
                // }

                if (checkForFiveAudios) {
                  //show what to do in toast
                  if (checkForFiveAudios) {
                    ToastFailedMsg("৫টি অডিও রেকর্ড করুন");
                  }
                  return;
                }

                else {
                  setDataPresent(false);
                  if (audio != null) {
                    audio.pause();
                    setPlayPauseState1(false);
                    setPlayPauseState2(false);
                    setPlayPauseState3(false);
                    setPlayPauseState4(false);
                    setPlayPauseState5(false);
                  }

                  if (getLoggedInUserId() === undefined) {
                    console.log("undefined");
                    setShow(true);
                    console.log("here ", 2);
                  } else {
                    console.log("details bolun", detailString);
                    const data = {
                      speaker_id: getLoggedInUserId(),
                      recording_area: localStorage.getItem('recordingArea'),
                      details: JSON.stringify(detailString)
                    };

                    console.log("here ", 3);
                    setDisabled(true);
                    console.log("qweq", index, " arrleng", arr.length);
                    if (flag === 0) {
                      setShowSubmitSpinner(true);
                      uploadAudio(data);
                      flag = 1;
                    }
                  }
                }
                // setSubmitState(true);

                // if(audios.audio1 !== ""){
                //     console.log("Code entered here");

                //     console.log(audios.audio1);

                //     uploadAudio(formData);
                // }else{
                //     console.log("++++")
                // }
              }
              //console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
              //if(device.)
            });
          })
          .catch((err) => {
            console.error(`${err.name}: ${err.message}`);
          });
      }
    }
  };

  // const submitDataAfterRegistration = (id: any) => {
  //   console.log("recording are after registration: ", recordingArea);

  //   const data = {
  //     speaker_id: id,
  //     recording_area: recordingArea,
  //   };

  //   uploadAudio(data);
  // };

  const Rerecord = (event: any) => {
    //@ts-ignore
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    setRecordingState(true);
    setRerecordingNumber(event);
    //setAudioNumber(event);
    console.log("RerecordingEvent", event);
  };

  let size: any = 0;
  useEffect(() => {
    size = recordedScripts.length
  }, [recordedScripts]);

  const stop = () => {
    console.log("In Bolun Component ... !");

    if (reRecordingState) {
      console.log("IN RE-recording State !!!", reRecordingNumber)
      // stopRecording("stop");
      if (reRecordingNumber == "audio1") {
        localStorage.setItem("latest_word_count", String(recordedScriptsDetails[0].word_count));
        // localStorage.setItem("latest_syllable_count", String(recordedScriptsDetails[0].syllable_count));
        localStorage.setItem("latest_phoneme_count", String(recordedScriptsDetails[0].phoneme_count));

        let currentAudioDuration = (Date.now() - startTime) / 1000;
        localStorage.setItem("latest_duration", String(currentAudioDuration));
        console.log("test", Date.now(), startTime, currentAudioDuration)
        console.log("duration of current audio: ", currentAudioDuration, recordedScriptsDetails[0]);
      }
      else if (reRecordingNumber == "audio2") {
        localStorage.setItem("latest_word_count", String(recordedScriptsDetails[1].word_count));
        // localStorage.setItem("latest_syllable_count", String(recordedScriptsDetails[1].syllable_count));
        localStorage.setItem("latest_phoneme_count", String(recordedScriptsDetails[1].phoneme_count));

        let currentAudioDuration = (Date.now() - startTime) / 1000;
        localStorage.setItem("latest_duration", String(currentAudioDuration));
        console.log("test", Date.now(), startTime, currentAudioDuration)
        console.log("duration of current audio: ", currentAudioDuration, recordedScriptsDetails[1]);
      } else if (reRecordingNumber == "audio3") {
        localStorage.setItem("latest_word_count", String(recordedScriptsDetails[2].word_count));
        // localStorage.setItem("latest_syllable_count", String(recordedScriptsDetails[2].syllable_count));
        localStorage.setItem("latest_phoneme_count", String(recordedScriptsDetails[2].phoneme_count));

        let currentAudioDuration = (Date.now() - startTime) / 1000;
        localStorage.setItem("latest_duration", String(currentAudioDuration));
        console.log("test", Date.now(), startTime, currentAudioDuration)
        console.log("duration of current audio: ", currentAudioDuration, recordedScriptsDetails[2]);
      } else if (reRecordingNumber == "audio4") {
        localStorage.setItem("latest_word_count", String(recordedScriptsDetails[3].word_count));
        // localStorage.setItem("latest_syllable_count", String(recordedScriptsDetails[3].syllable_count));
        localStorage.setItem("latest_phoneme_count", String(recordedScriptsDetails[3].phoneme_count));

        let currentAudioDuration = (Date.now() - startTime) / 1000;
        localStorage.setItem("latest_duration", String(currentAudioDuration));
        console.log("test", Date.now(), startTime, currentAudioDuration)
        console.log("duration of current audio: ", currentAudioDuration, recordedScriptsDetails[3]);
      } else if (reRecordingNumber == "audio5") {
        localStorage.setItem("latest_word_count", String(recordedScriptsDetails[4].word_count));
        // localStorage.setItem("latest_syllable_count", String(recordedScriptsDetails[4].syllable_count));
        localStorage.setItem("latest_phoneme_count", String(recordedScriptsDetails[4].phoneme_count));

        let currentAudioDuration = (Date.now() - startTime) / 1000;
        localStorage.setItem("latest_duration", String(currentAudioDuration));
        console.log("test", Date.now(), startTime, currentAudioDuration)
        console.log("duration of current audio: ", currentAudioDuration, recordedScriptsDetails[4]);
      }
      stopRecording("stop");
    } else {
      localStorage.setItem("latest_word_count", String(script[index].word_count));
      // localStorage.setItem("latest_syllable_count", String(script[index].syllable_count));
      localStorage.setItem("latest_phoneme_count", String(script[index].phoneme_count));

      let currentAudioDuration = (Date.now() - startTime) / 1000;
      localStorage.setItem("latest_duration", String(currentAudioDuration));
      console.log("test", Date.now(), startTime, currentAudioDuration)
      console.log("NOT IN RE-recording State !!!")
      stopRecording("stop");

      console.log("duration of current audio: ", currentAudioDuration, script[index]);
      // let latest_duration_str = localStorage.getItem("latest_duration");
      // let currentAudioDuration = latest_duration_str ? parseFloat(latest_duration_str) : 0.00;

      let words_per_second = script[index].word_count / currentAudioDuration;
      // let syllables_per_second = script[index].syllable_count / currentAudioDuration;
      let phonemes_per_second = script[index].phoneme_count / currentAudioDuration;

      console.log("wps pps:", words_per_second, phonemes_per_second);

      if (currentAudioDuration >= 0.5 && (phonemes_per_second >= 5 && phonemes_per_second <= 15)
        // ((words_per_second >= 0.5 && words_per_second <= 4.00) ||
        //     (syllables_per_second >= 1.00 && syllables_per_second <= 10.00))
      ) {
        console.log("Script Id ", script[index].id);

        localStorage.setItem("recorded_scripts_length", String(recordedScripts.length + 1));
        setRecordedScripts([...recordedScripts, script[index].description]);
        setRecordedScriptsDetails([...recordedScriptsDetails, script[index]]);

        setScriptId([...scriptId, script[index].id]);
        handleClick(1);
        setScriptIndex([...scriptIndex, index]);
        setstartRecord(false);
        if (scriptIndex.length === 4) {
          setAudioCount(true);
          setCheckForFiveAudios(false);
        }
      }
      else {
        // ToastFailedMsg("বেশি দ্রুত বা ধীরে পড়েছেন!");
      }
    }
  };

  const pause = (event: any) => {
    pauseRecording("pause");
    setstartRecord(false);
  };

  const resume = (event: any) => {
    resumeRecording("resume");
    // setstartRecord(true);
  };

  const playAudio = (event: any) => {
    console.log("Baldsjf ", event);

    if (audio != null) {
      audio.pause();
      setPlayPauseState1(false);
      setPlayPauseState2(false);
      setPlayPauseState3(false);
      setPlayPauseState4(false);
      setPlayPauseState5(false);
    }

    if (event == "audio1") {
      console.log("Event ", event);
      let audio1 = new Audio(audios.audio1);
      setAudio(audio1);
      audio1.play();
      setPlayPauseState1(true);
      audio1.addEventListener("ended", () => setPlayPauseState1(false));

      //
      // setPlayPauseState( (prevState:any) =>({
      //     ...prevState,
      //
      // }));

      // audio.addEventListener("ended",()=> setPlayPauseState({
      //     ...playpausestate,
      //     playpause1:false
      // }))
    } else if (event === "audio2") {
      console.log("Event ", event);
      let audio2 = new Audio(audios.audio2);
      setAudio(audio2);
      audio2.play();
      setPlayPauseState2(true);
      audio2.addEventListener("ended", () => setPlayPauseState2(false));
    } else if (event === "audio3") {
      console.log("Event ", event);
      let audio3 = new Audio(audios.audio3);
      setAudio(audio3);
      audio3.play();
      setPlayPauseState3(true);
      audio3.addEventListener("ended", () => setPlayPauseState3(false));
    } else if (event === "audio4") {
      console.log("Event ", event);
      let audio4 = new Audio(audios.audio4);
      setAudio(audio4);
      audio4.play();
      setPlayPauseState4(true);
      audio4.addEventListener("ended", () => setPlayPauseState4(false));
    } else {
      console.log("Event ", event);
      let audio5 = new Audio(audios.audio5);
      setAudio(audio5);
      audio5.play();
      setPlayPauseState5(true);
      audio5.addEventListener("ended", () => setPlayPauseState5(false));
    }
    // let audio = new Audio(url);
    //  setAudio(audio);
    //  audio.play();
    //
    //
    // // setPlayButtonDisable(true);
    //  audio.addEventListener("ended", () => setPlayButtonDisable(false));
  };

  const pauseAudio = (event: any) => {
    audio.pause();
    //setPlayButtonDisable(false);

    if (event === "audio1") {
      setPlayPauseState1(false);
    } else if (event === "audio2") {
      setPlayPauseState2(false);
    } else if (event === "audio3") {
      setPlayPauseState3(false);
    } else if (event === "audio4") {
      setPlayPauseState4(false);
    } else {
      setPlayPauseState5(false);
    }
  };

  const [showNull, setShowNull]: any = useState(false);
  const handleClick = (val: any) => {
    setTimeout(() => {
      //@ts-ignore
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      console.log("Index ", index);
      if (index >= 0 && index < 4) {
        setDataPresent(true);
      }
      if (index === 4) {
        setCheckForFiveAudios(false);
      }
      let recorded_scripts_length: any = localStorage.getItem("recorded_scripts_length");
      recorded_scripts_length = recorded_scripts_length ? parseInt(recorded_scripts_length) : 0;
      if (index + val < 0) {
        setIndex(0);
        setAudioURL("");
      }
      else if (index + val === script.length && recorded_scripts_length != 5) {
        setShowNull(true);
        setIndex(0);
        (async () => {
          console.log("get script called from 761")
          await getScripts();
          setAudioURL("");
        })();
      } else {
        setIndex(index + val);
        setAudioURL("");
      }
    }, 100);
  };

  const onRecordingAreaClick = (event: any) => {
    console.log("Recording Area ", event.target.value);
    if (event.target.value) {
      setLocalRecordingArea(event.target.value);
      setRecordingArea(event.target.value);
    }
  };


  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: 5000,
    });
  // console.log("lat:", coords?.latitude);
  // console.log("long:", coords?.longitude);
  // console.log("alt:", coords?.altitude);
  // console.log("speed:", coords?.speed);
  // console.log("head:", coords?.heading);

  // ====/
  // console.log("child-id: ", props.id);




  // function ariyeJanHandleClick(){
  //   setBtnState(btnState => !btnState);
  // }

  // const toggleClassCheck = btnState? 'clickstop': 'clickimg';
  const [playState, setPlayState]: any = useState('clickstop')

  useEffect(() => {
    console.log("playState: ", playState);

    if (playState === 'clickstop') {
      if (recorderPermission) {
        stop();
      }
    } else {
      if (recorderPermission) {
        console.log("Start Recording ... !");
        setStartTime(Date.now());
        startRecording("start");
      }
    }
  }, [playState]);

  useEffect(() => {
    console.log("btn state: ", btnState)
    toggolePlayPauseClick();
  }, [btnState]);

  const toggolePlayPauseClick = () => {
    setPlayState(btnState ? 'clickimg' : 'clickstop')
  }

  let [userPermissionChecked, setUserPermissionChecked]: any = useState(false);
  const toggleBtnState = async () => {
    if (getLoggedInUserId() === undefined) {
      ToastWarningMsg("নিবন্ধন বা লগইন করুন।");
    }
    else if (!recorderPermission) {
      ToastWarningMsg("Check your microphone permission");
      console.log("No mic for you!");
    }
    else if (userPermissionChecked == false) {
      //check if user has permission to record by api hit. consider api returns f=1 for having permission
      let res = await getUserPermissionFlag({ "userId": getLoggedInUserId() });
      if (res.status === 200 || res.status === 201) {
        let { bolun_read_flag } = await res.json();
        if (bolun_read_flag == 1) {
          //resume as before
          setUserPermissionChecked(true);
          setShowHelp(true);
          setBtnState(!btnState);
        }
        else {
          ToastFailedMsg("এই অংশে আপনার রেকর্ডের সময়সীমা শেষ। অন্য অংশে ভয়েস রেকর্ড করুন।");
        }
      }
    }
    else {
      //no need to check again for rest 4 records
      setShowHelp(true);
      setBtnState(!btnState);
    }
  }


  // Play stop end here

  // const [btnStatejomadin, setBtnStatejomadin] = useState(false)

  // function handleClickjomadin(){
  //   setBtnStatejomadin(btnStatejomadin => !btnStatejomadin);
  // }

  // let toggleClassCheckjomadin = btnStatejomadin? 'clickstop': 'btnactive';

  const [showHelp, setShowHelp]: any = useState(true);


  // //refresh page every 5000 msecs

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     window.location.reload();
  //     console.log("hewqe")
  //   }, 5000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 600);

  return (
    <>
      <div className="bodycontent">
        <div className="bodymenuitembolun">
          <div className="boluncotopokothon">
            <div className="homeread homereadmobile">
              <div className="bolunsununmobile">
                <span className="patharrowmobile">
                  <a href="/"><img src={mobilehome} alt="patharrow" /></a>
                  <span className="patharrowmobile"><a href="/">মূলপাতা</a></span>
                </span>
              </div>

              <Button variant="outline-primary" href="/reve-crowd-bn-conversation-multi" style={{ marginLeft: '5px' }}>কথোপকথন</Button>
              <Button variant="outline-success" href="/reve-crowd-bn-lecture" style={{ marginLeft: '5px' }}>লেকচার</Button>
              <div className="readingrulemobile">
                <div className="rruleitem">
                  <ul>
                    <li>
                      <a href="/reading-rules" className="">
                        <img src={readrule} alt="readrule" />পড়ার নিয়মাবলি
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bolunsunun">
              <span className="patharrow">
                <a href="/"><img src={patharrow} alt="patharrow" /></a>
                <span className="homearrow">মূলপাতা</span>
              </span>
            </div>
          </div>
          {rdd.isMobile ? null : (<div style={{ display: "inline-flex" }}><Button variant="outline-primary" href="/reve-crowd-bn-conversation-multi" style={{ marginLeft: '5px' }}>কথোপকথন</Button>
            <Button variant="outline-success" href="/reve-crowd-bn-lecture" style={{ marginLeft: '5px' }}>লেকচার</Button></div>)}
          <div className="readingrule">
            <div className="rruleitem">
              <ul>
                <li>
                  <a href="/reading-rules" className="readrule">
                    পড়ার নিয়মাবলি
                  </a>
                  <img src={readrule} alt="readrule" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="content-container innerbodyitem">
          <div className="mainrontainer">
            {/* {!showlacture ? ( */}
            <div className="textbar">
              <div className={` overallcontent kotopokothon`}>
                <div style={{ border: "0px solid green", position: "relative" }}
                  className={`content ${getActiveClass(
                    1,
                    "active-content"
                  )} boluncontent px-0`}>
                  <div className='container-fluid' style={{ display: "flex", justifyContent: 'space-between', border: "0px solid red", position: "absolute", top: "15px" }}>
                    <div>
                      <Timer expiryTimestamp={time} dataPresent={dataPresent} setDataPresent={setDataPresent} />
                    </div>
                    {!btnState ? (<div className="agiyejan">
                      <a onClick={() => handleClick(1)}
                        style={{ cursor: "pointer" }}
                      >
                        এড়িয়ে যান&nbsp;
                        <span style={{ cursor: "pointer" }}>
                          <img src={agiyejan} alt="agiyejan" />
                        </span>
                      </a>
                    </div>) : null}
                  </div>


                  <div className="bodycontentitem">
                    {noScriptFound ? (<p className='bodytextcontent' style={{ color: "red" }}><h3>এই মুহূর্তে যথেষ্ট স্ক্রিপ্ট নেই। কিছুক্ষণ পর আবার চেষ্টা করুন।</h3></p>)
                      : reRecordingNumber === "audio1" ? (
                        <p className='bodytextcontent'>{recordedScripts[0]}</p>
                      ) : reRecordingNumber === "audio2" ? (
                        <p className='bodytextcontent'>{recordedScripts[1]}</p>
                      ) : reRecordingNumber === "audio3" ? (
                        <p className='bodytextcontent'>{recordedScripts[2]}</p>
                      ) : reRecordingNumber === "audio4" ? (
                        <p className='bodytextcontent'>{recordedScripts[3]}</p>
                      ) : reRecordingNumber === "audio5" ? (
                        <p className='bodytextcontent'>{recordedScripts[4]}</p>
                      ) : playPauseState1 === true ? (
                        <p className='bodytextcontent'>{recordedScripts[0]}</p>
                      ) : playPauseState2 === true ? (
                        <p className='bodytextcontent'>{recordedScripts[1]}</p>
                      ) : playPauseState3 === true ? (
                        <p className='bodytextcontent'>{recordedScripts[2]}</p>
                      ) : playPauseState4 === true ? (
                        <p className='bodytextcontent'>{recordedScripts[3]}</p>
                      ) : playPauseState5 === true ? (
                        <p className='bodytextcontent'>{recordedScripts[4]}</p>
                      ) : audioCount && !reRecordingState ? (
                        <h3>অডিও সমূহ জমা দিন</h3>
                      ) : showNull ? null
                        : script[index] != null ?
                          (
                            <p className='bodytextcontent'>{script[index].description}</p>
                          ) : <p className='bodytextcontent' style={{ color: "red" }}><h3>এই মুহূর্তে যথেষ্ট স্ক্রিপ্ট নেই। কিছুক্ষণ পর আবার চেষ্টা করুন।</h3></p>
                    }
                  </div>
                  {/* <div className="bottomcontenttext">
                        <p className="bodydylog">
                          বলা শুরুর আগে
                          <span>
                            <img src={bolunbottom} alt="bolunbottom" />
                          </span>
                          ক্লিক করুন। তারপর স্পষ্ট ভাবে জোরে কথা বলুন।
                        </p>
                      </div> */}
                </div>
              </div>
            </div>
            <div className="clickbar">
              <div className="clickbaritem">
                {
                  audios.audio1 === "" && toggleClassCheck === "clickimg" && recorderPermission ? (
                    <div className={`${toggleClassCheck}`}>
                      <button className='clickpoint' onClick={toggleBtnState}
                      ><img src={clickpoint} alt="" />
                      </button>
                      <span className={`${showHelp ? 'tooltiptext' : 'hidecontent'}`}>
                        রেকর্ড করতে ক্লিক করুন। <br /> স্পষ্টভাবে জোরে পড়ুন।
                      </span>
                      {/* <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled" className="recordtooltips">
                  রেকর্ড করতে ক্লিক করুন। <br />
                  স্পষ্টভাবে জোরে পড়ুন।
                </Tooltip>
              }>
              <span className="d-inline-block">
                <button className="clickpoint" onClick={handleClick}>
                  <img src={clickpoint} alt="" />
                </button>
              </span>
            </OverlayTrigger> */}
                    </div>
                  ) : audios.audio1 === "" && toggleClassCheck === "clickstop" ? (
                    <div className={`${toggleClassCheck}`}>
                      <button className='clickpoint' onClick={toggleBtnState}
                      ><img src={clickpoint} alt="" />
                      </button>
                      <span className={`${showHelp ? 'tooltiptextstop' : 'hidecontent'}`}>পড়া শেষে ক্লিক করুন।</span>
                    </div>
                  ) : (
                    <div className={`${toggleClassCheck}`}>
                      <button className='clickpoint' onClick={toggleBtnState}
                        disabled={
                          (audioCount && !reRecordingState) ||
                          playPauseState1 ||
                          playPauseState2 ||
                          playPauseState3 ||
                          playPauseState4 ||
                          playPauseState5
                        }><img src={clickpoint} alt="" />
                      </button>
                    </div>
                  )
                }
              </div>
            </div>
          </div>

          <div className="rightsidebarwithseelct">
            <div className="rightsidebar">
              <div className="mobilerightsidebar">
                <ul>
                  {
                    audios.audio1 === "" ? (
                      <li className="comminrecord">
                        <span className="countitem">1</span>
                        <span className="recordstarthiden">রেকর্ডিং শুরু করুন</span>
                      </li>
                    ) : (
                      <li className="activenopading comminrecord">
                        <span className="countitem recordactive ">1</span>
                        <span className="recordstarthiden">
                          <ul>
                            {
                              !playPauseState1 ?
                                (
                                  playPauseState1 || reRecordingState ? (
                                    <li>
                                      {
                                        <span className="playstart">
                                          <img src={porasunte} alt="porasunte" />
                                        </span>
                                      }
                                    </li>
                                  ) : (
                                    <li onClick={() => playAudio("audio1")}>
                                      {
                                        <span className="playstart">
                                          <img src={porasunte} alt="porasunte" />
                                        </span>
                                      }
                                    </li>
                                  )
                                ) : (
                                  <li onClick={() => pauseAudio("audio1")}>
                                    {
                                      <span className="playstart">
                                        <img src={pauseicon} alt="porasunte" />
                                      </span>

                                    }
                                  </li>
                                )
                            }
                            <li>
                              {
                                (playButtonDisable || reRecordingState) ? (
                                  <span className="playreload"
                                  >
                                    <img src={reload} alt="porasunte" />
                                  </span>
                                ) : (
                                  <span className="playreload"
                                    onClick={() => Rerecord("audio1")}
                                  >
                                    <img src={reload} alt="porasunte" />
                                  </span>
                                )
                              }
                              {/* <span className="playreload"
                              onClick={() => Rerecord("audio1")}
                              >
                                <img src={reload} alt="porasunte" />
                              </span> */}
                            </li>
                          </ul>
                        </span>
                      </li>
                    )
                  }{
                    audios.audio2 !== "" ? (
                      <li className="activenopading comminrecord">
                        <span className="countitem recordactive ">2</span>
                        <span className="recordstarthiden">
                          <ul>
                            {
                              !playPauseState2 ?
                                (
                                  playPauseState2 || reRecordingState ? (
                                    <li>
                                      {
                                        <span className="playstart">
                                          <img src={porasunte} alt="porasunte" />
                                        </span>
                                      }
                                    </li>
                                  ) : (
                                    <li onClick={() => playAudio("audio2")}>
                                      {
                                        <span className="playstart">
                                          <img src={porasunte} alt="porasunte" />
                                        </span>
                                      }
                                    </li>
                                  )
                                ) : (
                                  <li onClick={() => pauseAudio("audio2")}>
                                    {
                                      <span className="playstart">
                                        <img src={pauseicon} alt="porasunte" />
                                      </span>

                                    }
                                  </li>
                                )
                            }
                            <li>
                              {
                                (playButtonDisable || reRecordingState) ? (
                                  <span className="playreload"
                                  >
                                    <img src={reload} alt="porasunte" />
                                  </span>
                                ) : (
                                  <span className="playreload"
                                    onClick={() => Rerecord("audio2")}
                                  >
                                    <img src={reload} alt="porasunte" />
                                  </span>
                                )
                              }
                              {/* <span className="playreload"
                              onClick={() => Rerecord("audio1")}
                              >
                                <img src={reload} alt="porasunte" />
                              </span> */}
                            </li>
                          </ul>
                        </span>
                      </li>
                    ) : audios.audio1 !== "" ? (
                      <li className="comminrecord">
                        <span className="countitem">2</span>
                        <span className="recordstarthiden">রেকর্ডিং শুরু করুন</span>
                      </li>
                    ) : (
                      <li className="rinactive comminrecord">
                        <span className="countitem ">2</span>
                      </li>
                    )
                  }{
                    audios.audio3 !== "" ? (
                      <li className="activenopading comminrecord">
                        <span className="countitem recordactive ">3</span>
                        <span className="recordstarthiden">
                          <ul>
                            {
                              !playPauseState3 ?
                                (
                                  playPauseState3 || reRecordingState ? (
                                    <li>
                                      {
                                        <span className="playstart">
                                          <img src={porasunte} alt="porasunte" />
                                        </span>
                                      }
                                    </li>
                                  ) : (
                                    <li onClick={() => playAudio("audio3")}>
                                      {
                                        <span className="playstart">
                                          <img src={porasunte} alt="porasunte" />
                                        </span>
                                      }
                                    </li>
                                  )
                                ) : (
                                  <li onClick={() => pauseAudio("audio3")}>
                                    {
                                      <span className="playstart">
                                        <img src={pauseicon} alt="porasunte" />
                                      </span>

                                    }
                                  </li>
                                )
                            }
                            <li>
                              {
                                (playButtonDisable || reRecordingState) ? (
                                  <span className="playreload"
                                  >
                                    <img src={reload} alt="porasunte" />
                                  </span>
                                ) : (
                                  <span className="playreload"
                                    onClick={() => Rerecord("audio3")}
                                  >
                                    <img src={reload} alt="porasunte" />
                                  </span>
                                )
                              }
                              {/* <span className="playreload"
                              onClick={() => Rerecord("audio1")}
                              >
                                <img src={reload} alt="porasunte" />
                              </span> */}
                            </li>
                          </ul>
                        </span>
                      </li>
                    ) : audios.audio2 !== "" ? (
                      <li className="comminrecord">
                        <span className="countitem">3</span>
                        <span className="recordstarthiden">রেকর্ডিং শুরু করুন</span>
                      </li>
                    ) : (
                      <li className="rinactive comminrecord">
                        <span className="countitem">3</span>
                      </li>
                    )
                  }{
                    audios.audio4 !== "" ? (
                      <li className="activenopading comminrecord">
                        <span className="countitem recordactive ">4</span>
                        <span className="recordstarthiden">
                          <ul>
                            {
                              !playPauseState4 ?
                                (
                                  playPauseState4 || reRecordingState ? (
                                    <li>
                                      {
                                        <span className="playstart">
                                          <img src={porasunte} alt="porasunte" />
                                        </span>
                                      }
                                    </li>
                                  ) : (
                                    <li onClick={() => playAudio("audio4")}>
                                      {
                                        <span className="playstart">
                                          <img src={porasunte} alt="porasunte" />
                                        </span>
                                      }
                                    </li>
                                  )
                                ) : (
                                  <li onClick={() => pauseAudio("audio4")}>
                                    {
                                      <span className="playstart">
                                        <img src={pauseicon} alt="porasunte" />
                                      </span>

                                    }
                                  </li>
                                )
                            }
                            <li>
                              {
                                (playButtonDisable || reRecordingState) ? (
                                  <span className="playreload"
                                  >
                                    <img src={reload} alt="porasunte" />
                                  </span>
                                ) : (
                                  <span className="playreload"
                                    onClick={() => Rerecord("audio4")}
                                  >
                                    <img src={reload} alt="porasunte" />
                                  </span>
                                )
                              }
                              {/* <span className="playreload"
                              onClick={() => Rerecord("audio1")}
                              >
                                <img src={reload} alt="porasunte" />
                              </span> */}
                            </li>
                          </ul>
                        </span>
                      </li>
                    ) : audios.audio3 !== "" ? (
                      <li className="comminrecord">
                        <span className="countitem">4</span>
                        <span className="recordstarthiden">রেকর্ডিং শুরু করুন</span>
                      </li>
                    ) : (
                      <li className="rinactive comminrecord">
                        <span className="countitem ">4</span>
                      </li>
                    )
                  }{
                    audios.audio5 !== "" ? (
                      <li className="activenopading comminrecord">
                        <span className="countitem recordactive ">5</span>
                        <span className="recordstarthiden">
                          <ul>
                            {
                              !playPauseState5 ?
                                (
                                  playPauseState5 || reRecordingState ? (
                                    <li>
                                      {
                                        <span className="playstart">
                                          <img src={porasunte} alt="porasunte" />
                                        </span>
                                      }
                                    </li>
                                  ) : (
                                    <li onClick={() => playAudio("audio5")}>
                                      {
                                        <span className="playstart">
                                          <img src={porasunte} alt="porasunte" />
                                        </span>
                                      }
                                    </li>
                                  )
                                ) : (
                                  <li onClick={() => pauseAudio("audio5")}>
                                    {
                                      <span className="playstart">
                                        <img src={pauseicon} alt="porasunte" />
                                      </span>

                                    }
                                  </li>
                                )
                            }
                            <li>
                              {
                                (playButtonDisable || reRecordingState) ? (
                                  <span className="playreload"
                                  >
                                    <img src={reload} alt="porasunte" />
                                  </span>
                                ) : (
                                  <span className="playreload"
                                    onClick={() => Rerecord("audio5")}
                                  >
                                    <img src={reload} alt="porasunte" />
                                  </span>
                                )
                              }
                              {/* <span className="playreload"
                              onClick={() => Rerecord("audio1")}
                              >
                                <img src={reload} alt="porasunte" />
                              </span> */}
                            </li>
                          </ul>
                        </span>
                      </li>
                    ) : audios.audio4 !== "" ? (
                      <li className="comminrecord">
                        <span className="countitem">5</span>
                        <span className="recordstarthiden">রেকর্ডিং শুরু করুন</span>
                      </li>
                    ) : (
                      <li className="rinactive comminrecord">
                        <span className="countitem ">5</span>
                      </li>
                    )
                  }
                </ul>
              </div>

              <div className="rightsidebarcontent nomobile">
                {
                  audios.audio1 === "" ? (
                    <div className="singleitem">
                      <ul>
                        <li> <span className='recordstart'>রেকর্ডিং শুরু করুন</span> </li>
                        <li> <span className='countitem'>1</span></li>
                      </ul>
                    </div>
                  ) :
                    (
                      <div className="singleitem fullitem">
                        <ul>
                          {
                            !playPauseState1 ? (
                              <li> <button className='playstart'
                                onClick={() => playAudio("audio1")}
                                disabled={playPauseState1 || reRecordingState}
                              >
                                <img src={porasunte} alt="porasunte" /> </button>
                              </li>
                            ) :
                              (
                                <li> <button className='playstart'
                                  onClick={() => pauseAudio("audio1")}
                                  disabled={!playPauseState1}
                                >
                                  <img src={pauseicon} alt="porasunte" /> </button>
                                </li>
                              )
                          }
                          <li> <button className='playreload'
                            onClick={() => Rerecord("audio1")}
                            disabled={playButtonDisable || reRecordingState}
                          >
                            <img src={reload} alt="porasunte" />
                          </button> </li>
                          <li> <span className='countitem'>1</span></li>
                        </ul>
                      </div>
                    )
                }

                {

                  audios.audio2 !== "" ? (
                    <div className="singleitem fullitem">
                      <ul>
                        {
                          !playPauseState2 ? (
                            <li> <button className='playstart'
                              onClick={() => playAudio("audio2")}
                              disabled={playPauseState2 || reRecordingState}
                            >
                              <img src={porasunte} alt="porasunte" /> </button>
                            </li>
                          ) :
                            (
                              <li> <button className='playstart'
                                onClick={() => pauseAudio("audio2")}
                                disabled={!playPauseState2}
                              >
                                <img src={pauseicon} alt="porasunte" />
                              </button>
                              </li>
                            )
                        }
                        <li> <button className='playreload'
                          onClick={() => Rerecord("audio2")}
                          disabled={playButtonDisable || reRecordingState}
                        > <img src={reload} alt="porasunte" /> </button> </li>
                        <li> <span className='countitem'>2</span></li>
                      </ul>
                    </div>
                  ) : audios.audio1 !== "" ? (
                    <div className="singleitem">
                      <ul>
                        <li> <span className='recordstart'>রেকর্ডিং শুরু করুন</span> </li>
                        <li> <span className='countitem'>2</span></li>
                      </ul>
                    </div>
                  ) : (
                    <div className="singleitem onlyitem">
                      <ul>
                        <li> <span className='countitem'>2</span></li>
                      </ul>
                    </div>
                  )
                }
                {

                  audios.audio3 !== "" ? (
                    <div className="singleitem fullitem">
                      <ul>
                        {
                          !playPauseState3 ? (
                            <li> <button className='playstart'
                              onClick={() => playAudio("audio3")}
                              disabled={playPauseState3 || reRecordingState}
                            >
                              <img src={porasunte} alt="porasunte" /> </button>
                            </li>
                          ) :
                            (
                              <li> <button className='playstart'
                                onClick={() => pauseAudio("audio3")}
                                disabled={!playPauseState3}
                              >
                                <img src={pauseicon} alt="porasunte" />
                              </button>
                              </li>
                            )
                        }
                        <li> <button className='playreload'
                          onClick={() => Rerecord("audio3")}
                          disabled={playButtonDisable || reRecordingState}
                        > <img src={reload} alt="porasunte" /> </button> </li>
                        <li> <span className='countitem'>3</span></li>
                      </ul>
                    </div>
                  ) : audios.audio2 !== "" ? (
                    <div className="singleitem">
                      <ul>
                        <li> <span className='recordstart'>রেকর্ডিং শুরু করুন</span> </li>
                        <li> <span className='countitem'>3</span></li>
                      </ul>
                    </div>
                  ) : (
                    <div className="singleitem onlyitem">
                      <ul>
                        <li> <span className='countitem'>3</span></li>
                      </ul>
                    </div>
                  )
                }

                {
                  audios.audio4 !== "" ? (
                    <div className="singleitem fullitem">
                      <ul>
                        {
                          !playPauseState4 ? (
                            <li> <button className='playstart'
                              onClick={() => playAudio("audio4")}
                              disabled={playPauseState4 || reRecordingState}
                            >
                              <img src={porasunte} alt="porasunte" /> </button>
                            </li>
                          ) :
                            (
                              <li> <button className='playstart'
                                onClick={() => pauseAudio("audio4")}
                                disabled={!playPauseState4}
                              >
                                <img src={pauseicon} alt="porasunte" />
                              </button>
                              </li>
                            )
                        }
                        <li> <button className='playreload'
                          onClick={() => Rerecord("audio4")}
                          disabled={playButtonDisable || reRecordingState}
                        > <img src={reload} alt="porasunte" /> </button> </li>
                        <li> <span className='countitem'>4</span></li>
                      </ul>
                    </div>
                  ) : audios.audio3 ? (
                    <div className="singleitem">
                      <ul>
                        <li> <span className='recordstart'>রেকর্ডিং শুরু করুন</span> </li>
                        <li> <span className='countitem'>4</span></li>
                      </ul>
                    </div>
                  ) : (
                    <div className="singleitem onlyitem">
                      <ul>
                        <li> <span className='countitem'>4</span></li>
                      </ul>
                    </div>
                  )
                }

                {

                  audios.audio5 !== "" ? (
                    <div className="singleitem fullitem">
                      <ul>
                        {
                          !playPauseState5 ? (
                            <li> <button className='playstart'
                              onClick={() => playAudio("audio5")}
                              disabled={playPauseState5 || reRecordingState}
                            >
                              <img src={porasunte} alt="porasunte" /> </button>
                            </li>
                          ) :
                            (
                              <li> <button className='playstart'
                                onClick={() => pauseAudio("audio5")}
                                disabled={!playPauseState5}
                              >
                                <img src={pauseicon} alt="porasunte" />
                              </button>
                              </li>
                            )
                        }
                        <li> <button className='playreload'
                          onClick={() => Rerecord("audio5")}
                          disabled={playButtonDisable || reRecordingState}
                        > <img src={reload} alt="porasunte" /> </button> </li>
                        <li> <span className='countitem'>5</span></li>
                      </ul>
                    </div>
                  ) : audios.audio4 ? (
                    <div className="singleitem">
                      <ul>
                        <li> <span className='recordstart'>রেকর্ডিং শুরু করুন</span> </li>
                        <li> <span className='countitem'>5</span></li>
                      </ul>
                    </div>
                  ) : (
                    <div className="singleitem onlyitem">
                      <ul>
                        <li> <span className='countitem'>5</span></li>
                      </ul>
                    </div>
                  )
                }
                {/* { 
            count < 5 ? (
              <div className="singleitem">
                <ul>
                  <li> <span className='recordstart'>রেকর্ডিং শুরু করুন</span> </li>
                  <li> <span className='countitem'>{count+1}</span></li>
                </ul>
              </div>
            ):null
          } */}
                {/* <div className="singleitem onlyitem">
            <ul>
              <li> <span className='countitem'>2</span></li>
            </ul>
          </div> */}
              </div>
            </div>

            <div className="selectarea selectareaoption">
              {
                !audioCount ? (
                  <div className="selectarea selectareasm">
                    <select name="recordingarea" required={true} className="selectcontroll"
                      value={recordingArea} onChange={onRecordingAreaClick}
                      disabled>
                      {
                        recordingAreas.map(
                          area => {
                            return <option value={area} key={area}>{area}</option>
                          }
                        )
                      }

                    </select>
                  </div>
                ) : (
                  <div className="selectarea selectareasm">
                    <select name="recordingarea" className="selectcontroll"
                      value={recordingArea} onChange={onRecordingAreaClick}
                    >
                      {
                        recordingAreas.map(
                          area => {
                            return <option value={area} key={area}>{area}</option>
                          }
                        )
                      }
                    </select>
                  </div>
                )
              }
              <div className="buttonaea buttonaeasm">
                {
                  // audioCount ? (
                  //   <button className='btnjomadin btnactive' onClick={onSubmit}
                  //    > {
                  //     showSubmitSpinner ?
                  //       (<Spinner animation="border" variant="light" />) :
                  //       (<span className='disjomadin' style={{ cursor: "pointer" }}> <img src={jomadin} alt="jomadin" /> জমা দিন</span>)
                  //   }
                  //   </button>
                  // ) : (
                  <button className='btnjomadin' disabled> <span className='disjomadin'> <img src={disjomadin} alt="jomadin" /> </span> জমা দিন</button>
                  // )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}