import jwt_decode from "jwt-decode";
import {ToastFailedMsg, ToastSuccessMsg} from "./toast";


const apiBaseUrl = process.env.REACT_APP_PUBLIC_API_URL ? process.env.REACT_APP_PUBLIC_API_URL : "http://localhost:8001"

export const request = async (
    url: string,
    method: string,
    payload?: any,
    headers?: any
) => {
    headers = headers || {};
    // let token = getToken()

    let token = getToken().accessToken

    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }

    url = apiBaseUrl + url;

    let req;
    try {
        req = await fetch(url, { method, mode: "cors", cache: "no-cache",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                ...headers,
            },
            body: JSON.stringify(payload),
        })
        await checkTokenStatus(req);    // check req
        return req;
    } catch (error) {
        // ToastFailedMsg("Can't fetch data from server")
        return {status: 500, json: () => {}, blob:()=> {}, ok: false}
    }
};


export const getToken = () => {

    let accessToken = localStorage.getItem("accessToken")
    let refreshToken = localStorage.getItem("refreshToken")
    let details = localStorage.getItem("details");
    let name = localStorage.getItem("name")
    if ((accessToken !== '' && accessToken !== undefined) && (refreshToken !== '' && refreshToken !== undefined) && (details !== '' && details !== undefined) && (name !== '' && name !== undefined)) {
        return {accessToken, refreshToken, details, name}
    }
    return {accessToken, refreshToken, details, name}

}

export const setToken = (token: any) => {
    console.log(token);
    if (token) {
        localStorage.setItem("accessToken", token.accessToken)
        localStorage.setItem("refreshToken", token.refreshToken)
        localStorage.setItem("details", token.details)
    }
    else{
        localStorage.setItem("details", "{}")
    }
}
export const isLoggedIn = () => {
    let {accessToken, refreshToken, details} = getToken();
    if (accessToken && refreshToken) {
        return true;
    }
    // checkTokenStatus()
    return false;
}

export const unsetToken = () => {
    localStorage.setItem("accessToken", '')
    localStorage.setItem("refreshToken", '')
    localStorage.setItem("details", '')
    localStorage.setItem("name", '')
}

export const getName =() =>{
    return localStorage.getItem("name");
}

export const setLocalRecordingArea =  (val:any) => {
    localStorage.setItem('recordingArea', val)
}

export const getLocalRecordingArea =  () => {
    return localStorage.getItem('recordingArea')
}


export const renewToken = async (url: string) => {
    let token = getToken().refreshToken

    console.log('renewToken', token);

    url = apiBaseUrl + url;

    return fetch(url, {
        method: "post",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
};

export const checkTokenStatus = async (response: any) => {

    if (response.status === 403) {
        // console.log('msg', 'access token expired');
       // ToastFailedMsg("Access Token Expired")

        let res = await renewToken("/auth/renew");

        if (res.status === 200) {
            let {data}: any = await res.json();
            // console.log('msg', data);
            setToken({
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
                details :data.details,
                name: data.name
            })

            ToastSuccessMsg("Renew Access Token")
        } else {
            // reset token
            setToken({
                accessToken: "",
                refreshToken: "",
                details:"",
                roles: "",
                name: ""
            })
            window.location.reload();
        }
    }
    /*let {accessToken, refreshToken}:any = getToken()
    let {exp, roles}:any = jwt_decode(accessToken);

    if(new Date(exp).getTime() > new Date().getTime()) {
        console.log('AccessToken expired, Refresh Token Is: ', refreshToken);
        // let {exp, roles}:any = jwt_decode(refreshToken);
        //todo: need to get renew access token using refresh token
        console.log('msg', 'todo: need to get renew access token using refresh token');
    }*/
}


export const decodeToken: any = () => {

    try {
        let token = getToken().accessToken;
        if (typeof token === "string") {
            let decoded:any = jwt_decode(token);
            localStorage.setItem("name",decoded.name)
            return decoded;
            //console.log('get roles', decoded);
        }
    }catch (error){}

    return;
}

export const getRoles = () => {
    try {
        let {roles} = decodeToken();
        return roles
    }catch (err) {}
    return []
}

export const getLoggedInUserId = () => {
    try {
        // console.log("http-info: ", decodeToken());
        
        let {userId} = decodeToken();
        return userId;
    }catch (err){}
    return;
}

export const requestFileUpload = (
    url: string,
    method: string,
    payload?: any,
    headers?: any
) => {
    headers = headers || {};
    let token = getToken().accessToken;
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }

    url = apiBaseUrl + url;

    let req = fetch(url, {
        method,
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Access-Control-Allow-Origin": "*",
            ...headers,
        },
        body: payload,
    });

    req.then(async (response) => {
        await checkTokenStatus(response);
        return response;
    })

    return req;

};

export const post = (url: string, payload: any) => {
    return request(url, "POST", payload);
};

export const put = (url: string, payload: any) => {
    return request(url, "PUT", payload);
};

export const get = (url: string) => {
    return request(url, "GET");
};

export const getWithPayload =(url: string,payload:any) =>{
    console.log("get all the data");
  return request(url,"GET",payload) ;
};

export const postFile = (url: string, payload: any) => {
    return requestFileUpload(url, "POST", payload);
};

export const del = (url: string) => {
    return request(url, "DELETE");
};

export const patch = (url: string, payload: any) => {
    return request(url, "PATCH", payload);
};

export const deleteItem = (url: string) => {
    return request(url, "DELETE");
};

export const update = (url: string, paylad: any) => {
    return request(url, "PATCH", paylad);
};



// for pythonbackend

export const requestFileUploadPython = (
    url: string,
    method: string,
    payload?: any,
    headers?: any
) => {
    headers = headers || {};
    let token = getToken().accessToken;
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }

    if(url == "/postCheckedAudio"){
        url = `${apiUrl}:5590/submit-listen/`;
    }
    else if( url =="/submit-conversation"){
        // url = "http://amarkantho.revesoft.com:5200/submit-conversation/";
        url = `${apiUrl}:5200/submit-conversation/`;
    }
    else if( url == "/submit-lecture"){
        url = `${apiUrl}:5300/submit-lecture/`;
        // url = `http://amarkantho.revesoft.com:5300/submit-lecture`;
    }
    else{
        url = `${apiUrl}:5555/submit-audio/`;
        // url = "https://dev.revesoft.com:5505/submit-audio/"
        // url = "http://amarkantho.revesoft.com:5200/bolun-test/";
    }

    let req = fetch(url, {
        method,
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Access-Control-Allow-Origin": "*",
            ...headers,
        },
        body: payload,
    });

    req.then(async (response) => {
        await checkTokenStatus(response);
        return response;
    })

    return req;

};
const apiUrl = process.env.REACT_APP_ELK_PYTHON
export const requestToPython = async (
    url: string,
    method: string,
    payload?: any,
    headers?: any
) => {
    headers = headers || {};
    // let token = getToken()

    let token = getToken().accessToken

    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }
    if(url == "/auth/login"){
        url = `${apiUrl}:5559/login/`
    }
    else if(url == "/get-scripts"){
        url = `${apiUrl}:4001/script-api-1/`
    }
    else if(url == "/total-time-actor"){
        url = `${apiUrl}:5550/total-time-actor/`
    }
    else if(url == "/monthwise-duration"){
        url = `${apiUrl}:5550/monthwise-duration/`
    }
    else if(url == "/get-day-wise-duration"){
        url = `${apiUrl}:5560/get-day-wise-duration/`
    }
    else if(url == "/get-day-wise-duration-listen"){
        url = `${apiUrl}:5560/get-day-wise-duration-shunun/`
    }
    else if(url == "/get-speak-total-duration"){
        url = `${apiUrl}:5560/get-speak-total-duration/`
    }
    else if(url == "/get-listen-total-duration"){
        url = `${apiUrl}:5560/get-listen-total-duration/`
    }
    else if(url == "/submit-unused-scripts"){
        url = `${apiUrl}:5555/return-scripts/`
    }
    else if(url == "/fetch-audio"){
        url = `${apiUrl}:5650/fetch-audio/`
        // url = "http://119.148.4.20:5650/fetch-audio/"
    }
    else if(url == "/getAudiosWithScript"){
        url = `${apiUrl}:5750/get-speech-info/`;
    }
    else if(url == "/reset-password"){
        url = `${apiUrl}:5559/reset-password/`;
        // url = "http://119.148.4.20:5719/reset-password/"
    }
    else if(url == "/get-topics"){
        // url = "http://amarkantho.revesoft.com:5200/get-topics/";
        url = `${apiUrl}:5201/get-topics/`;
    }
    else if(url == "/find-by-username"){
        // url = "http://amarkantho.revesoft.com:5200/find-by-username/";
        url = `${apiUrl}:5201/find-by-username/`;
    }
    else if(url == "/user-permission-check"){
        url = `${apiUrl}:5250/user-permission-check/`;
    }
    else if( url == "/get-lecture-slide"){
        url = `${apiUrl}:5301/get-lecture-slide`;
        // url = `http://amarkantho.revesoft.com:5301/get-lecture-slide`;
    }
    else if( url == "/get-current-config"){
        // url = `http://amarkantho.revesoft.com:5610/get-current-config`;
        url = `${apiUrl}:5610/get-current-config/`;
    }
    else if( url == "/update-config"){
        // url = `http://amarkantho.revesoft.com:5610/update-config`;
        url = `${apiUrl}:5610/update-config/`;
    }
    else{
        url = `${apiUrl}:5557/register/`;
    }
    let req;
    try {
        // console.log("payload: ", payload)
        req = await fetch(url, { method, mode: "cors", cache: "no-cache",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                ...headers,
            },
            body: JSON.stringify(payload),
        })
        await checkTokenStatus(req);    // check req
        return req;
    } catch (error) {
        // ToastFailedMsg("Can't fetch data from server")
        return {status: 500, json: () => {}, blob:()=> {}, ok: false}
    }
};
export const postFiletoPython = (url: string, payload: any) => {
    return requestFileUploadPython(url, "POST", payload);
};

export const postToPython = (url: string, payload: any) => {
    return requestToPython(url, "POST", payload);
};

export const getFromPython = (url: string) => {
    return requestToPython(url, "Post");
};