import React, { ReactElement, useEffect, useState } from "react";
import "./all.scss";
import "./ttsstyle2.scss";
import "./style.scss";
import "./home.scss";
import bolunicon from "./images/stts/bolunicon.png";
import bolunkotha from "./images/stts/bolunkotha.png";
import kothasunun from "./images/stts/kothasunun.png";
import leftchart from "./images/stts/leftchart.png";
import leftwave from "./images/stts/leftwave.png";
import rightwave from "./images/stts/rightwave.png";
import sununuicon from "./images/stts/sununuicon.png";
import sununtitle from "./images/stts/sununtitle.png";
import bangla_logo from "./images/Bangla_Logo.png";
import bccl_logo from "./images/bccl_ogo.png";
import ict_logo from "./images/ict_logo.png";
import MenuComponent from "./Menu.Component";
import BolunShununModal from "./assets/js/BolunShununModal";
import Header from "./Header";
import Modal from 'react-bootstrap/Modal';
import { AiFillCaretRight, AiFillFacebook } from "react-icons/ai";
import { IconContext } from "react-icons";
import {
  getMonthWiseDuration,
  getTotalDurationAndPage,

} from "./crowd_source.service";
import { setLocalRecordingArea } from '../../common/http';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CrowdDashBoard() {
  const [totalCollectedDuration, setTotalCollectedDuration] = useState("");
  const [totalSpeakDuration, setTotalSpeakDuration] = useState("");
  const [totalListenDuration, setTotalListenDuration] = useState("");
  const [totalSpeakers, setTotalSpeakers] = useState(0);
  const [totalListeners, setTotalListeners] = useState(0);
  let [speakerGraphData, setSpeakerGraphData]: any = useState([]);
  let [listenerGraphData, setListenerGraphData]: any = useState([]);
  let [bolunAccepted, setBolunAccepted]: any = useState(0.000);
  let [shununAccepted, setShununAccepted]: any = useState("");

  let [speakerId, setSpeakerId]: any = useState(null);
  const [showBolunShununModal, setShowBolunShununModal]: any = useState(false)
  const [onModalOption, setOnModalOption]: any = useState(1)


  // @ts-ignore
  /* String.prototype.getDigitBanglaFromEnglish = function () {
    var retStr = this;
    for (var x in finalEnlishToBanglaNumber) {
      // @ts-ignore
      retStr = retStr.replace(new RegExp(x, 'g'), finalEnlishToBanglaNumber[x]);
    }
    return retStr;
  }; */

  // var english_number = "123456";
  // @ts-ignore
  // var bangla_converted_number = english_number.getDigitBanglaFromEnglish();
  // console.log("bangla conversion",bangla_converted_number);
  const BnToEn = (n: any) => n.replace(/[০-৯]/g, (d: any) => "০১২৩৪৫৬৭৮৯".indexOf(d));
  const EnToBn = (n: any) => n.replace(/\d/g, (d: any) => "০১২৩৪৫৬৭৮৯"[d]);

  const [totalCollectedDurationMinute, setTotalCollectedDurationMinute]: any = useState(null);
  const [totalSpeakDurationMinute, setTotalSpeakDurationMinute]: any = useState(null);
  const [totalListenDurationMinute, setTotalListenDurationMinute]: any = useState(null);

  useEffect(() => {
    (async () => {
      await fetchTotalDurationAndSpeakers();
    })();
  }, []);

  useEffect(() => {
    console.log("modal: ", showBolunShununModal);

  }, [showBolunShununModal]);

  const fetchTotalDurationAndSpeakers = async () => {
    let res = await getTotalDurationAndPage();
    if (res.status == 200) {
      let { data } = await res.json();
      console.log(data);

      let totalTimeHourCollected = String(Math.floor(parseFloat(data.collected_duration) / 3600));
      let totalTimeMinuteCollected = String(Math.round((parseFloat(data.collected_duration) % 3600) / 60));

      setTotalCollectedDuration(EnToBn(totalTimeHourCollected));
      setTotalCollectedDurationMinute(EnToBn(totalTimeMinuteCollected));
      console.log(`Total Duration Bolun: ${EnToBn(totalTimeHourCollected)} ঘণ্টা ${EnToBn(totalTimeMinuteCollected)} মিনিট`);

      let totalTimeHourSpeak = String(Math.floor(parseFloat(data.duration) / 3600));
      let totalTimeMinuteSpeak = String(Math.round((parseFloat(data.duration) % 3600) / 60));

      setTotalSpeakDuration(EnToBn(totalTimeHourSpeak));
      setTotalSpeakDurationMinute(EnToBn(totalTimeMinuteSpeak));
      console.log(`Total Duration Bolun: ${EnToBn(totalTimeHourSpeak)} ঘণ্টা ${EnToBn(totalTimeMinuteSpeak)} মিনিট`);

      let totalActorsSpeak = String(data.actors);
      setTotalSpeakers(EnToBn(totalActorsSpeak));

      let totalTimeHourListen = String(Math.floor((parseFloat(data.duration_shunun) / 3600)));
      let totalTimeMinuteListen = String(Math.round((parseFloat(data.duration_shunun) % 3600) / 60));

      setTotalListenDuration(EnToBn(totalTimeHourListen));
      setTotalListenDurationMinute(EnToBn(totalTimeMinuteListen));
      console.log(`Total Duration Shunun: ${EnToBn(totalTimeHourListen)} ঘণ্টা ${EnToBn(totalTimeMinuteListen)} মিনিট`);

      let totalActorsListen = String(data.actors_shunun);
      setTotalListeners(EnToBn(totalActorsListen));

      setBolunAccepted(data.bolun_accepted);
      setShununAccepted(data.shunun_accepted);
    }

    let res2 = await getMonthWiseDuration();
    if (res2.status == 200) {
      let { data, data_listen } = await res2.json();
      console.log("Monthwise Bolun: ", data);
      console.log("Monthwise Shunun: ", data_listen);
      let graphData = data.slice(-12);

      let monthsInBengali = [
        'জান', 'ফেব', 'মার্চ', 'এপ্রিল', 'মে', 'জুন',
        'জুলাই', 'আগস্ট', 'সেপ্ট', 'অক্টো', 'নভে', 'ডিসে'
      ];

      let convertedData = graphData.map((item: any) => {
        const monthIndex = parseInt(item.month) - 1;
        const bengaliMonth = monthsInBengali[monthIndex];
        const shortenedYear = `${item.year.slice(-2)}`;
        const bengaliYear = EnToBn(shortenedYear);
        return {
          month: `${bengaliMonth} '${bengaliYear}`,
          duration: parseFloat((item.duration / 3600).toFixed(8))
        };
      });
      setSpeakerGraphData(convertedData);
      // console.log(speakerGraphData)

      let graphDataListen = data_listen.slice(-12);

      let convertedDataListen = graphDataListen.map((item: any) => {
        const monthIndex = parseInt(item.month) - 1;
        const bengaliMonth = monthsInBengali[monthIndex];
        const shortenedYear = `${item.year.slice(-2)}`;
        const bengaliYear = EnToBn(shortenedYear);
        return {
          month: `${bengaliMonth} '${bengaliYear}`,
          duration: parseFloat((item.duration / 3600).toFixed(8))
        };
      });
      setListenerGraphData(convertedDataListen);
      // console.log(listenerGraphData);
    }
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    //console.log("payload ",`${payload[0].value}`);

    if (active && payload && payload.length) {
      var hours = String(Math.floor(payload[0].value));
      var minutes = String(Math.round((payload[0].value - Math.floor(payload[0].value)) * 60));

      return (
        <div className="custom-tooltip">
          <p className="intro">{EnToBn(hours)} ঘণ্টা {EnToBn(minutes)} মিনিট</p>
        </div>
      );
    }

    return null;
  };

  const styleObj = {
    fontSize: 14,
    color: "#4a54f1",
    textAlign: "center",
    paddingTop: "100px",
  }

  const showModal = () => {
    setShowBolunShununModal(true)
  }


  const onRecordingModal = (val: any) => {
    setOnModalOption(val);
    showModal();
  }

  const [showRecordingAreaSelection, setShowRecordingAreaSelection]: any = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => { setShow(false); };
  const handleShow = () => setShow(true);

  const recordingAreas = [
    // "রুমের মধ্যে",
    // "রুমের বাইরে",
    // "মাঠে",
    // "মার্কেট",
    // "রোড",
    // "উন্মুক্ত স্থান",
    // "অন্যান্য জনবহুল স্থান"
    "রুমের মধ্যে",
    "রুমের বাইরে",
    "মার্কেটে",
    "মাঠে",
    "রাস্তায়",
    "উন্মুক্ত স্থানে",
    "অন্যান্য জনবহুল স্থানে"
  ]

  const onRecordingAreaChange = (val: any) => {
    // alert(val)
    handleClose();
    console.log("recording area: ", val);
    setLocalRecordingArea(val);
  }

  return (
    <div className="bodybg">
      {/* <MenuComponent /> */}
      <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
        centered><Modal.Body className="bolunshununmodal">
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle recordingmodal"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              রেকর্ডিং এলাকা
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {
                recordingAreas.map(
                  area => {
                    return <li key={area}>
                      <a href={
                        (process.env.REACT_APP_ROUTER_BASE || "") +
                        "/reve-crowd-bn-lecture"
                        // "/reve-crowd-bn-conversation-multi"
                      } className="dropdown-item" onClick={() => onRecordingAreaChange(area)}> {area}</a>
                    </li>;
                  }
                )
              }
            </ul>
          </div>
        </Modal.Body></Modal>
      <Header openModal={showModal} isOuter={true} />
      {/* {showBolunShununModal && <BolunShununModal closeModal={() => setShowBolunShununModal(false)} modalOption={onModalOption}/>} */}

      <div className="wavecontent webcontentop">
        <div className="leftwave">
          <div className="sununkotha">
            <span>
              <img className="img-fluid" src={bolunkotha} alt="" />
            </span>
            <a
              className="boluniconbtn"
              // href={
              //   (process.env.REACT_APP_ROUTER_BASE || "") +
              //   `/reve-crowd-bn-bolun/id?${speakerId}`
              // }
              onClick={() => handleShow()}
            >
              <img className="img-fluid" src={bolunicon} alt="" />
            </a>
          </div>

          <span className="leftwaveig">
            <img className="img-fluid" src={leftwave} alt="" />
          </span>
        </div>

        <div className="leftwave">
          <div className="topgrapharea">
            <div className="graphtop">
              <div className="righttop">
                <div className="bullate bullateright"></div>
                <div className="bullatecontent">
                  <p>অডিও রেকর্ডকারী</p>
                  {totalSpeakers === 0 ? null : (
                    <h6>{totalSpeakers}<span style={{ fontSize: 8 }}> জন</span></h6>
                  )}
                </div>
              </div>

              <div className="righttop">
                <div className="bullate"></div>
                <div className="bullatecontent">
                  <p>মোট রেকর্ডকৃত সময়</p>
                  {totalSpeakDuration === "" ? null : (
                    <h6>{totalSpeakDuration}<span style={{ fontSize: 8 }}> ঘণ্টা </span>{totalSpeakDurationMinute}<span style={{ fontSize: 8 }}> মিনিট</span></h6>
                  )}
                </div>
              </div>

              {/* <div className="righttop">
                <div className="bullate bullateright"></div>
                <div className="bullatecontent">
                  <p>মোট সংগৃহীত সময়</p>
                  {totalCollectedDuration === "" ? null : (
                    <h6>{totalCollectedDuration}<span style={{ fontSize: 8 }}> ঘণ্টা </span>{totalCollectedDurationMinute}<span style={{ fontSize: 8 }}> মিনিট</span></h6>
                  )}
                </div>
              </div> */}

              <div className="righttop">
                <div className="bullate"></div>
                <div className="bullatecontent">
                  <p>মোট গ্রহণযোগ্য সময় </p>
                  <h6>{EnToBn(String(Math.floor(bolunAccepted / 3600)))}<span style={{ fontSize: 8 }}> ঘণ্টা </span>{EnToBn(String(Math.round((bolunAccepted % 3600) / 60)))}<span style={{ fontSize: 8 }}> মিনিট</span></h6>
                </div>
              </div>
            </div>

            <div className="graphbody">
              <ResponsiveContainer
                width="100%"
                height="100%"
                minHeight={300}
                minWidth={200}>
                <BarChart
                  //className="graphbar"
                  width={700}
                  height={400}
                  data={speakerGraphData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" interval={0} angle={30} dx={20} dy={4} />
                  <YAxis allowDataOverflow={true} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="duration" fill="#DDD4FF" />
                  {/* <Bar dataKey="duration" fill="#42f5b9" /> */}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

      <div className="wavecontentbolun">
        <div className="leftwaveimg">
          <div className="topgrapharea leftbottomgraph">
            <div className="graphtop">
              <div className="righttop">
                <div className="bullate bullateaudiolight"></div>
                <div className="bullatecontent">
                  <p>অডিও যাচাইকারী</p>
                  {totalListeners === 0 ? null : (
                    <h6>{totalListeners}<span style={{ fontSize: 8 }}> জন</span></h6>
                  )}
                </div>
              </div>

              <div className="righttop">
                <div className="bullateaudiodip"></div>
                <div className="bullatecontent">
                  <p>মোট যাচাইকৃত সময়</p>
                  {totalListenDuration === "" ? null : (
                    <h6> {totalListenDuration}<span style={{ fontSize: 10 }}> ঘণ্টা </span>{totalListenDurationMinute}<span style={{ fontSize: 10 }}> মিনিট </span></h6>
                  )}
                </div>
              </div>

              <div className="righttop">
                <div className="bullate bullateaudiolight"></div>
                <div className="bullatecontent">
                  <p>মোট গ্রহণযোগ্য সময়</p>
                  <h6>{EnToBn(String(Math.floor(shununAccepted / 3600)))}<span style={{ fontSize: 8 }}> ঘণ্টা </span>{EnToBn(String(Math.round((shununAccepted % 3600) / 60)))}<span style={{ fontSize: 8 }}> মিনিট</span></h6>
                </div>
              </div>
            </div>

            <div className="graphbody">
              <ResponsiveContainer
                width="100%"
                height="100%"
                minHeight={300}
                minWidth={200}
              >
                <BarChart
                  //className="graphbar"
                  width={700}
                  height={400}
                  data={listenerGraphData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" interval={0} angle={30} dx={20} dy={4} />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="duration" fill="#A1E6FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="leftwave inright">
          <div className="sununkotha">
            <span>
              <img className="img-fluid" src={sununtitle} alt="" />
            </span>
            <a
              className="sununicon"
              href={
                (process.env.REACT_APP_ROUTER_BASE || "") +
                "/reve-crowd-bn-shunun"
              }
            >
              <img className="img-fluid" src={sununuicon} alt="" />
            </a>
          </div>

          <span className="rightwaveig">
            <img className="img-fluid" src={rightwave} alt="" />
          </span>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="footer-areabottom">
              <ul className="footer-item">
                <li className="developed">
                  <span>
                    Designed & Developed by&nbsp;
                    <a
                      style={{
                        color: "blue",
                      }}
                      href="https://www.revesoft.com/">
                      REVE Systems
                    </a>
                  </span>
                </li>
              </ul>
              <ul className="footer-item">
                <li className="copyclass">
                  <a>mail: news4reve@gmail.com</a>
                  <a href="https://www.facebook.com/Amar-Kontho-107355312220670"><IconContext.Provider
                    value={{ color: '#1877F2', size: '28px' }}
                  ><AiFillFacebook /></IconContext.Provider></a>
                </li>
              </ul>
              <ul className="footer-item">
                <li>
                  <a>
                    <img className="img-fluid" src={bangla_logo} alt="bangla" />
                  </a>
                </li>
                <li>
                  <a>
                    <img className="img-fluid" src={bccl_logo} alt="BCC" />
                  </a>
                </li>
                <li>
                  <a>
                    <img className="img-fluid" src={ict_logo} alt="ict" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
