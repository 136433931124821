import React, { Component, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import add from "./images/stts/add.png";
import speakerone from "./images/stts/speaker1.png";
import Card from 'react-bootstrap/Card';
import { HiTrash } from "react-icons/hi";
import "./tabconversation.css"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IconContext } from "react-icons";

function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "black" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "black" }}
            onClick={onClick}
        />
    );
}

export default function SpeakerCarousel({ speakerNames, getName, plusClicked, removeSpeaker }: any) {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    const [slideHeight, setSlideHeight] = useState(0);

    useEffect(() => {
        let desiredHeight: any = document.querySelector('.speakderareamulit')?.clientHeight;
        console.log(desiredHeight);
        if (slideHeight == 0) {
            setSlideHeight(100);
        }
    }, []);

    const [show, setShow]: any = useState(false);
    const [nameToBeDeleted, setNameToBeDeleted]: any = useState("");
    const handleClose = () => setShow(false);
    const handleShow = (name: any) => {
        setNameToBeDeleted(name);
        setShow(true)
    };
    const removeSpeakerAndCloseModal = () => {
        removeSpeaker(nameToBeDeleted);
        handleClose();
    }
    return (
        <div style={{ height: slideHeight, border: "0px solid red" }}>
            <Modal show={show} onHide={handleClose}>
                {/* <Modal.Header closeButton>
                    <Modal.Title>
                        আপনি কি এই স্পিকারকে সরানোর বিষয়ে নিশ্চিত?</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <h5>আপনি কি এই স্পিকারকে সরানোর বিষয়ে নিশ্চিত?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        ফিরে যান
                    </Button>
                    <Button variant="danger" onClick={() => removeSpeakerAndCloseModal()}>
                        মুছে ফেলুন
                    </Button>
                </Modal.Footer>
            </Modal>
            <Slider {...settings}>
                {
                    speakerNames.map((name: any, i: any) => (
                        <div className="card" style={{ height: "inherit", border: "0px solid red" }} key={name.toString()}>
                            {
                                <div style={{ height: slideHeight * 0.8, border: "0px solid green", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                    <img src={speakerone} className="img-fluid mx-auto" height="50px" width="50px" alt="..." />
                                </div>
                            }
                            {
                                name !== undefined && name !== null ? (
                                    <div className="card-body bg-dark text-white m-0 p-0" style={{ height: slideHeight * 0.2, border: "0px solid blue" }}>
                                        <h5 className="card-title m-0 p-0">{name}</h5>
                                    </div>
                                ) : (
                                    <p className="speakertitletwo">স্পিকার 1</p>
                                )
                            }
                            {
                                <div className="card-footer m-0 p-0" style={{ height: slideHeight * 0.2, border: "0px solid red" }}>
                                    {
                                        name == getName() ? <div style={{ opacity: "0.5" }}><HiTrash /></div> :
                                            <IconContext.Provider
                                                value={{ color: 'red' }}>
                                                <a style={{ cursor: "pointer" }} onClick={() => { handleShow(name) }}><HiTrash /></a>
                                            </IconContext.Provider>
                                    }
                                </div>
                            }
                        </div>
                    ))
                }
                {
                    speakerNames.length != 5 ? (
                        <div className="card" style={{ height: "inherit", border: "0px solid red" }} >
                            {
                                <div style={{ height: slideHeight * 1.2, border: "0px solid green", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                    <a style={{ cursor: "pointer" }} onClick={() => plusClicked()}>
                                        <img src={add} className="img-fluid mx-auto" height="50px" width="50px" alt="..." />
                                    </a>
                                </div>
                            }
                        </div>) : null
                }
            </Slider>
        </div>
    )
}