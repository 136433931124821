import React, { useState } from 'react'
import "./assets/css/header.css";
import logo from './images/stts/logo.png';
import { ReactComponent as UserIcon } from './assets/webfonts/sojib.svg';
import BottomArrow from './images/stts/bottomarrow.png';
import { useNavigate } from 'react-router-dom';
import { getLoggedInUserId, getName, unsetToken, setLocalRecordingArea } from '../../common/http';
import { Button } from 'react-bootstrap';
import BolunShununModal from './assets/js/BolunShununModal';
import Modal from 'react-bootstrap/Modal';

export default function Header(props: any) {
  const [show, setShow] = useState(false);

  const handleClose = () => { setShow(false); setIsRecordAreaShow(false); };
  const handleShow = () => setShow(true);
  const [isRecordAreaShow, setIsRecordAreaShow] = useState(false);
  const openRecoringArea = () => {
    setIsRecordAreaShow(true);
  }

  const recordingAreas = [
    // "রুমের মধ্যে",
    // "রুমের বাইরে",
    // "মাঠে",
    // "মার্কেট",
    // "রোড",
    // "উন্মুক্ত স্থান",
    // "অন্যান্য জনবহুল স্থান"
    "রুমের মধ্যে",
    "রুমের বাইরে",
    "মার্কেটে",
    "মাঠে",
    "রাস্তায়",
    "উন্মুক্ত স্থানে",
    "অন্যান্য জনবহুল স্থানে"
  ]


  const onRecordingAreaChange = (val: any) => {
    // alert(val)
    handleClose();
    console.log("recording area: ", val);
    setLocalRecordingArea(val);
  }

  const navigate = useNavigate();

  const onLogout = ((e: any) => {
    unsetToken();
    navigate('/')
  });

  // console.log("modal f: ", props.openModal);


  return (
    <div className="navbg">
      <div className='topnavbar'>

        <div className="withlogomenu">
          <div className="logoarea">
            <a href={(process.env.REACT_APP_ROUTER_BASE || "") + "/"}>
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div className="midmenuarea">
            <div className="nav">
              <ul>
                {/* <li>
                  <a
                    href={
                      (process.env.REACT_APP_ROUTER_BASE || "/")
                    }>
                    হোম
                  </a>
                </li> */}
                {
                  // props.isOuter ? (<li>
                  //   {/* <a className='ongshonin' href={
                  //       (process.env.REACT_APP_ROUTER_BASE || "") +
                  //       "/reve-crowd-bn-bolun"
                  //     }>অংশ নিন</a> */}
                  //     <a href='#'
                  //     onClick={() => props.openModal()}>
                  //       অংশ নিন
                  //     </a>
                  // </li>):null
                }
                {
                  props.isOuter ? (<li>
                    {/* <a className='ongshonin' href={
                        (process.env.REACT_APP_ROUTER_BASE || "") +
                        "/reve-crowd-bn-bolun"
                      }>অংশ নিন</a> */}
                    <a href='#'
                      onClick={handleShow}>
                      অংশ নিন
                    </a>
                  </li>) : null
                }
                {
                  props.isOuter ? (
                    <li><a href={(process.env.REACT_APP_ROUTER_BASE || "") + "/about"}>আমাদের সম্পর্কে</a></li>
                  ) : null
                }
              </ul>
            </div>
          </div>
        </div>

        <div className="rightmenu">
          <div className="loginregistration">
            {getLoggedInUserId() ? (
              <div className="portfolioarea">
                <div className="nameitem">
                  <p>{getName()}</p>
                </div>
                <div className="headerprofile">
                  <a href={
                    (process.env.REACT_APP_ROUTER_BASE || "") +
                    "/user-dashboard"}>
                    <UserIcon />
                  </a>
                </div>
                <div className="headerprofile">
                  <a href={
                    (process.env.REACT_APP_ROUTER_BASE || "") +
                    "/user-dashboard"}
                  >
                    <img src={BottomArrow} alt="logo" />
                  </a>
                </div>
                {/* <div>
            <button className='btnlogout' onClick={onLogout}> লগ আউট </button>
          </div> */}
                {/* <div className="profileselect">
          <select name="" id="">
            <option value="">
              name
            </option>
            <option value="">
              Uername
            </option>
            <option value="">
              Profile
            </option>
          </select>
        </div> */}
              </div>
            ) : (
              <ul>
                <li><a className="probeshkorun" href={
                  (process.env.REACT_APP_ROUTER_BASE || "") +
                  "/log-in"} >প্রবেশ করুন</a></li>
                <li><a className="nibondonkorun" href={
                  (process.env.REACT_APP_ROUTER_BASE || "") +
                  "/sign-up"} >নিবন্ধন করুন</a></li>
              </ul>
            )
            }
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
        centered>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        {!isRecordAreaShow ? (
          <Modal.Body className="bolunshununmodal">
            <div className="hagroup">
              <button
                className="habtn btnbolunmodal"
                onClick={openRecoringArea}
              >
                বলুন
              </button>

              {/* <span className="hatext">রেকর্ডটি জমা হবে </span> */}
            </div>
            <div className="nagroup">
              <a href={
                (process.env.REACT_APP_ROUTER_BASE || "") +
                "/reve-crowd-bn-shunun"
              }>
                <button
                  className="nabtn btnsununmodal"
                // onClick={() => closeModal(false)}
                >
                  শুনুন
                </button>
              </a>
            </div>
          </Modal.Body>) : (
          <Modal.Body className="bolunshununmodal">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle recordingmodal"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                রেকর্ডিং এলাকা
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                {
                  recordingAreas.map(
                    area => {
                      return <li key={area}>
                        <a href={
                          (process.env.REACT_APP_ROUTER_BASE || "") +
                          "/reve-crowd-bn-lecture"
                          // "/reve-crowd-bn-conversation-multi"
                        } className="dropdown-item" onClick={() => onRecordingAreaChange(area)}>{area}</a>
                      </li>;
                    }
                  )
                }
              </ul>
            </div>
          </Modal.Body>)
        }
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  )
}