import React, { useState } from "react";
import signup from "./images/stts/signup.png";
import probesh from "./images/stts/probeshkorun.png";
import { getLoggedInUserId, postToPython, setToken } from "../../common/http";
import { ToastFailedMsg, ToastSuccessMsg, ToastWarningMsg } from "../../common/toast";
import { useNavigate } from "react-router-dom";

interface FormDataI {
    username: string;
    password: string;
}

export default function PasswordResetModal({
    onDataSubmit,
}: any) {
    const [formData, setFormData] = useState<FormDataI>({
        username: "",
        password: "",
    });

    const navigate = useNavigate();

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "password") {
            if (checkerpass != e.target.value) {
                setPasswordMatches(false);
            }
            else {
                setPasswordMatches(true);
            }
        }

        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

    };
    const [passwordMatches, setPasswordMatches]: any = useState(true);
    const [checkerpass, setCheckerpass]: any = useState(null);
    const passwordMatchCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckerpass(e.target.value);
        if (e.target.value != formData.password) {
            console.log("password doesnt match");
            setPasswordMatches(false);
        }
        else {
            console.log("password matches");
            setPasswordMatches(true);
        }
    }

    const enterIntoSystem = () => {
        // navigate("/log-in");
        window.location.reload();
    };


    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log("formdata", formData.username);
        if (passwordMatches) {
            if (formData.username === "" || formData.password === "") {
                ToastFailedMsg("সংরক্ষণ সফল হয়নি");
            }
            else if (formData.password.length < 6) {
                ToastWarningMsg("পাসওয়ার্ড ৬ অক্ষর বা তার বেশি");
            }
            else {
                let res = await postToPython("/reset-password", formData);
                console.log(res)
                let data = await res.json();
                if (res.status === 200) {
                    //success

                    //let data = await res.json();
                    //console.log("Log In ", data);
                    //console.log("msg ", data.data);
                    setToken(data.data);
                    // navigate('/reve-crowd-bn')
                    //window.location.reload();
                    ToastSuccessMsg("সংরক্ষণ সফল হয়েছে");
                    setTimeout(() => enterIntoSystem(), 2000);
                }
                else {
                    ToastFailedMsg("সংরক্ষণ সফল হয়নি");
                }
            }
        }
        else {
            ToastWarningMsg("পাসওয়ার্ড মিলে নাই");
        }
    };

    const getUserId = () => {
        // window.location.reload()
        onDataSubmit(getLoggedInUserId());
        console.log("User Id ", getLoggedInUserId());
    };

    return (
        <div
            className="container"
            style={{
                marginTop: "15%",
                marginBottom: "10%",
            }}>
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-5">
                    <div className="loginform shadow-dark2">
                        <h4>পাসওয়ার্ড রিসেট করুন</h4>

                        <form onSubmit={onFormSubmit}>
                            <div className="row mtopbottom">
                                <div className="col ps-0" style={{ padding: 10 }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="ইউজার নেম"
                                        aria-label="First name"
                                        name="username"
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>

                            <div className="row mtopbottom">
                                <div className="col ps-0" style={{ padding: 10 }}>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="নতুন পাসওয়ার্ড দিন"
                                        aria-label="First name"
                                        name="password"
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row mtopbottom">
                                <div className="col ps-0" style={{ padding: 10 }}>
                                    <input
                                        type="password"
                                        //className="form-control"
                                        className={
                                            passwordMatches
                                                ? "form-control"
                                                : "error form-control"
                                        }
                                        placeholder="পাসওয়ার্ড নিশ্চিত করুন"
                                        aria-label="First name"
                                        name="passwordchecker"
                                        onChange={passwordMatchCheck}
                                    />
                                </div>
                            </div>

                            {/*<div className="row mtopbottom">*/}
                            {/*    <div className="col ps-0">*/}
                            {/*        <div className="form-check">*/}
                            {/*            <input*/}
                            {/*                className="form-check-input"*/}
                            {/*                type="checkbox"*/}
                            {/*                id="gridCheck"*/}
                            {/*            />*/}
                            {/*            <label className="form-check-label" htmlFor="gridCheck">*/}
                            {/*                আমাকে মনে কর*/}
                            {/*            </label>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="col passreset">*/}
                            {/*        <a href="" className="passreset">পাসওয়ার্ড রিসেট করুন</a>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="row mtopbottomlogin">
                                <div className="col p-0">
                                    <div className="loginbutton">
                                        <button
                                            className="mjomadinbutton"
                                            type="submit"
                                            style={{
                                                border: "none",
                                                // background: "none",
                                            }}>
                                            {/* <img src={probesh} alt=""/> */}
                                            সংরক্ষণ করুন
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
