import React, { useState, useEffect, useRef, useMemo, createRef } from 'react'
// import "./bodycontent.css"
// import Footer from './Footer.js'
import bolun from "./images/stts/bolun.png"
import bolunbottom from "./images/stts/bolunbottom.png"
import sunun from "./images/stts/sunun_icon.png"
import Card from 'react-bootstrap/Card';
import readrule from "./images/stts/readrule.png"
import agiyejan from "./images/stts/agiyejan.png"
import clickpoint from "./images/stts/clickhover.png"
import patharrow from "./images/stts/patharrow.png";
import mobilehome from "./images/stts/home.png";
import stop from "./images/stts/stop.png";
import pause from "./images/stts/pause.png";
import check from "./images/stts/check.png";
import speakerone from "./images/stts/speaker1.png";
import speakertwo from "./images/stts/speaker2.png";
import add from "./images/stts/add.png";
import reload from "./images/stts/reload.png"
import disjomadin from "./images/stts/disjomadin.png"
import jomadin from "./images/stts/jomadin.png"
import Modal from './assets/js/Modal'
import LongRunningLoading from '../../common/LongRunningLoading';
import { getConvTopics, findUserByUserEmail, getUserByUserEmail, getUserPermissionFlag } from "./crowd_source.service";
import { getLocalRecordingArea, getLoggedInUserId, getName, setLocalRecordingArea } from '../../common/http'
import { TestWarning, ToastFailedMsg, ToastWarningMsg } from '../../common/toast';
import useRecorder from '../../common/AudioRecorder';
import * as rdd from 'react-device-detect';
import { useGeolocated } from 'react-geolocated';
import Button from "react-bootstrap/Button";
import { FaEllipsisV } from 'react-icons/fa';
import Overlay from 'react-bootstrap/Overlay';
import SpeakerCarousel from './Conversation.speaker.carousel.component'
import Timer from "./Timer";
import Spinner from 'react-bootstrap/Spinner';
import jwt_decode from "jwt-decode";

export default function ConversationMultiComponent(props: any) {

    const [id, setId]: any = useState();
    let [recorderPermission, setRecorderPermission]: any = useState(false);
    let [recordingArea, setRecordingArea]: any = useState();

    let [
        audioURL,
        setAudioURL,
        isRecording,
        startRecording,
        stopRecording,
        pauseRecording,
        resumeRecording,
        uploadAudio,
        checkRecording,
        count,
        audios,
        reRecordingState,
        setRecordingState,
        reRecordingNumber,
        setRerecordingNumber,
        setScriptId,
        scriptId,
    ]: any = useRecorder(props = { type: 1 });

    const handler = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = "";
    };

    const [dataPresent, setDataPresent]: any = useState(false);
    const [enableJoma, setEnableJoma]: any = useState(false);
    useEffect(() => {
        // the handler for actually showing the prompt
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload

        // if the form is NOT unchanged, then set the onbeforeunload
        if (dataPresent) {
            window.addEventListener("beforeunload", handler);
            // clean it up, if the dirty state changes
            return () => {
                window.removeEventListener("beforeunload", handler);
            };
        }
        // since this is not dirty, don't do anything
        return () => { };
    }, [dataPresent]);

    const skip = () => {
        if (topicIndex + 1 == topic.length) {
            getTopic();
        }
        else {
            setTopicIndex(topicIndex + 1);
        }
    }

    const onRecordingAreaClick = (event: any) => {
        console.log("Recording Area ", event.target.value);
        if (event.target.value) {
            setLocalRecordingArea(event.target.value);
            setRecordingArea(event.target.value);
        }
    };

    useEffect(() => {
        getTopic();
        setRecordingArea(getLocalRecordingArea());
        // console.log("Speaker Id " + getLoggedInUserId());
        setSpeakerId(getLoggedInUserId());

        console.log("local recording area: ", getLocalRecordingArea())

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(function (stream) {
                if (count > 0 && !recorderPermission) {
                    window.location.reload();
                }
                setRecorderPermission(true);
                console.log("MIC Found");
            })
            .catch(function (err) {
                setRecorderPermission(false);
                ToastWarningMsg("Check your microphone permission");
                console.log("No mic for you!");
            });

        localStorage.removeItem('latest_duration');
        localStorage.removeItem('latest_word_count');
        localStorage.removeItem('latest_syllable_count');
        localStorage.removeItem('recorded_scripts_length');

        if (props.id) {
            setId(props.id);
            getActiveClass(1, "active-tabs");
            toggleTab(1)
        }
        // const interval = setInterval(() => setShowHelp(false), 3000);
        // return () => {
        //     clearInterval(interval);
        // };
    }, []);

    const [ToggleState, setToggleState] = useState(1);

    const toggleTab = (index: any) => {
        setToggleState(index);
    };

    const getActiveClass = (index: any, className: any) =>
        ToggleState === index ? className : "";

    // play stop button show hide

    const [btnState, setBtnState] = useState(false);

    function handleClick() {
        setBtnState((btnState) => !btnState);
    }

    useEffect(() => {
        toggolePlayPauseClick();
    }, [btnState]);

    const [playState, setPlayState]: any = useState('clickstop');
    let [startTime, setStartTime]: any = useState(Date.now());
    const [startRecord, setstartRecord]: any = useState(true);
    const [checkForOneAudio, setCheckForOneAudio]: any = useState(true);
    const [isMinSpeakersAvailable, setIsMinSpeakersAvailable]: any = useState(false)

    const toggolePlayPauseClick = () => {
        setPlayState(btnState ? 'clickimg' : 'clickstop');
    }

    useEffect(() => {
        // console.log("playState: ", playState);

        if (playState === 'clickstop') {
            if (recorderPermission) {
                stop();
            }
        } else {
            if (recorderPermission) {
                console.log("Start Recording ... !");
                setStartTime(Date.now());
                startRecording("start");
            }
        }
    }, [playState]);

    const stop = () => {
        console.log("In convo Component ... !");
        let currentAudioDuration = (Date.now() - startTime) / 1000;
        localStorage.setItem("latest_duration", String(currentAudioDuration));
        stopRecording("stop");
        setCheckForOneAudio(false);
        console.log("duration of current audio: ", currentAudioDuration);

        if (currentAudioDuration > 1.00
            // ((words_per_second >= 0.5 && words_per_second <= 4.00) ||
            //     (syllables_per_second >= 1.00 && syllables_per_second <= 10.00))
        ) {
            setstartRecord(false);
            // setEnableJoma(true);
            ToastWarningMsg("আমরা এই মুহূর্তে নতুন ডেটা কালেকশন করছি না।")
        }
        else {
            ToastFailedMsg("রেকর্ডিং ১ সেকেণ্ডেরও কম! ঠিকভাবে রেকর্ড করুন।")
        }
    };


    // let toggleClassCheck = btnState ? "clickstopupdate" : "clickimgupdate";
    let toggleClassCheckmulti = btnState ? "lactureplay" : "lacturemulti";
    let toggleClassCheck = btnState ? "clickstop" : "clickimg";

    const [show, setShow] = useState(false);
    // const [showclose, setShowclose] = useState(false);
    // speaking area show hide end

    // Lacture button active

    const [showlacture, setShowlacture] = useState(false);

    let lectureactive = showlacture ? "lectureactive" : "";

    // Show doito and toito
    const [showdoito, setShowdoito] = useState(false);

    let showdoitoactive = showdoito ? "doitotoactive" : "";
    // Show doito and toito
    const [showtoito, setShowtoito] = useState(false);

    let showtoitoactive = showtoito ? "toitotoactive" : "";

    // Show doito and toito
    // Lacture button active
    const [btnStateclose, setBtnStateclose] = useState(true);

    // Lacture button inactive

    let toggleClassCheckclose = btnStateclose
        ? " kotopokothon "
        : " cothopokothongroupnone";
    // let toggleClassCheckcloseshow = btnStateclose? '  ': ' cothopokothongroupbtn';
    let kbtactive = btnStateclose ? "kbtactive" : "afterclick";
    let divcontenthide = btnStateclose ? "overallhide" : "overallcontent";

    // =============Modal State start here ==========================

    const [openModal, setOpenModal] = useState(false);

    // =============Modal State start here ==========================

    let [pageLoader, setPageLoader]: any = useState(true);


    const [speakerId, setSpeakerId]: any = useState("")
    const [userEmail, setUserEmail]: any = useState("")
    let [topic, setTopic]: any = useState({});
    let speakerCount = 1;

    const recordingAreas = [
        "রুমের মধ্যে",
        "রুমের বাইরে",
        "মার্কেটে",
        "মাঠে",
        "রাস্তায়",
        "উন্মুক্ত স্থানে",
        "অন্যান্য জনবহুল স্থানে"
    ]


    const getTopic = async () => {
        // console.log("gettopic called");
        let res = await getConvTopics();

        if (res.status === 200 || res.status === 201) {
            try {
                let { data } = await res.json();
                // console.log("topic", data);
                setTopic(data);
                setTopicIndex(0);
            }
            catch (err) {
                console.log(err);
            }
        }
    }


    const [showTopic, setShowTopic]: any = useState(true);
    const [speakers, setSpeakers]: any = useState();


    const onSpeakerSearch = async () => {
        if (userEmail) {
            let res = await findUserByUserEmail({ 'username': userEmail })

            console.log("res: ", res);

            if (res.status === 200 || res.status === 201) {
                let { userId, userName } = await res.json();
                console.log("Multi user info by email: ", userId, userName);
                setUserEmail("");
                if (!speaker_list.includes(userId) && speaker_list.length < 5 && userId != "NOT_FOUND") {
                    setSpeaker_list([...speaker_list, userId]);
                    setSpeaker_names([...speaker_names, userName]);
                }
                else if (userId == "NOT_FOUND") {
                    ToastFailedMsg("Not a registered user")
                }
                else {
                    ToastWarningMsg("Speaker already added");
                }
            }
        }
        setShowTopic(true);
    };


    const [showSubmitSpinner, setShowSubmitSpinner]: any = useState(false);
    const [disabled, setDisabled]: any = useState(false);
    const [playPauseState1, setPlayPauseState1]: any = useState(false);
    const [playPauseState2, setPlayPauseState2]: any = useState(false);
    const [playPauseState3, setPlayPauseState3]: any = useState(false);
    const [playPauseState4, setPlayPauseState4]: any = useState(false);
    const [playPauseState5, setPlayPauseState5]: any = useState(false);
    let [audio, setAudio]: any = useState(null);
    let [topicIndex, setTopicIndex]: any = useState(0);

    let [userPermissionChecked, setUserPermissionChecked]: any = useState(false);
    const toggoleBtnState = async () => {
        if (btnState) {
            let currentAudioDuration = (Date.now() - startTime) / 1000;
            if (currentAudioDuration <= speaker_names.length * 5) {
                ToastFailedMsg("প্রতিজন স্পিকারের জন্য কমপক্ষে ৫ সেকেন্ড রেকর্ড করুন।");
                return
            }
        }
        if (getLoggedInUserId() === undefined) {
            ToastWarningMsg("নিবন্ধন বা লগইন করুন।");
        }
        else if (!recorderPermission) {
            ToastWarningMsg("Check your microphone permission");
            console.log("No mic for you!");
        }
        else if (checkForOneAudio == true) {
            let res = await getUserPermissionFlag({ "userId": getLoggedInUserId() });
            if (res.status === 200 || res.status === 201) {
                let { bolun_conversation_flag, bolun_conversation_mono_flag, bolun_conversation_di_flag, bolun_conversation_multi_flag } = await res.json();
                if ((speaker_list.length == 1 && bolun_conversation_mono_flag) || (speaker_list.length == 2 && bolun_conversation_di_flag) || (speaker_list.length > 2 && bolun_conversation_multi_flag)) {
                    //resume as before
                    console.log("bolun_convo_flag", bolun_conversation_flag);
                    setUserPermissionChecked(true);
                    setBtnState(!btnState);
                    setStartTimer(!startTimer);
                    setDataPresent(true);
                }
                else {
                    // ToastFailedMsg("এই অংশে আপনার রেকর্ডের সময়সীমা শেষ। অন্য অংশে ভয়েস রেকর্ড করুন।");
                    if (speaker_list.length == 1) {
                        ToastFailedMsg("আপনি ১ জন স্পিকারের ‌‌‌জন্য আপনার রেকর্ডিং সীমা অতিক্রম করেছেন৷");
                    }
                    else if (speaker_list.length == 2) {
                        ToastFailedMsg("আপনি ২ জন স্পিকারের ‌‌‌জন্য আপনার রেকর্ডিং সীমা অতিক্রম করেছেন৷");
                    }
                    else {
                        ToastFailedMsg("আপনি ৩ জন বা তার বেশি স্পিকারের ‌‌‌জন্য আপনার রেকর্ডিং সীমা অতিক্রম করেছেন৷");
                    }
                }
            }
        }
        else {
            ToastWarningMsg("রেকর্ডকৃত অডিও জমা দিন।");
        }
    }

    const showWarning = () => {
        ToastWarningMsg("স্পিকার যোগ করুন।");
    }

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 5000,
        });

    const onSubmit = async () => {
        if (!showSubmitSpinner) {
            // setSubmitState(true);

            // if(audios.audio1 !== ""){
            //     console.log("Code entered here");

            //     console.log(audios.audio1);

            //     uploadAudio(formData);
            // }else{
            //     console.log("++++")
            // }
            console.log(rdd.isAndroid, rdd);
            let useragent: any = {};
            let geoData: any = {};
            let deviceString: any = {};


            // useragent.isMobile = rdd.isMobile;
            // useragent.isAndroid = rdd.isAndroid;
            // useragent.isIOS = rdd.isIOS;
            // useragent.isIOS13 = rdd.isIOS13;   
            //useragent.isWindows = rdd.isWindows;
            //useragent.isMacOs = rdd.isMacOs;
            // useragent.isChrome = rdd.isChrome;
            // useragent.isFirefox = rdd.isFirefox;
            // useragent.isEdge = rdd.isEdge;
            // useragent.isSamsungBrowser=rdd.isSamsungBrowser;
            // useragent.isBrowser = rdd.isBrowser;
            useragent.isDesktop = rdd.isDesktop;
            useragent.mobileVendor = rdd.mobileVendor;
            useragent.mobileModel = rdd.mobileModel;
            useragent.osName = rdd.osName;
            useragent.osVersion = rdd.osVersion;
            useragent.browserName = rdd.browserName;
            useragent.fullBrowserVersion = rdd.fullBrowserVersion;

            //let useragentString= JSON.stringify(useragent);
            //console.log(useragentString);

            let geoDataString: any;

            if (!isGeolocationAvailable) {
                console.log("Your browser does not support Geolocation");
            } else if (!isGeolocationEnabled) {
                console.log("Geolocation is not enabled");
            } else {
                console.log("Latitude !!!", coords?.latitude);

                geoData.latitude = coords?.latitude;
                geoData.longitude = coords?.longitude;
                geoData.altitude = coords?.altitude;
                geoData.heading = coords?.heading;
                geoData.speed = coords?.speed;

                //geoDataString=JSON.stringify(geoData);
                //console.log(geoDataString);
            }


            let detailString: any = {};
            if (!navigator.mediaDevices?.enumerateDevices) {
                console.log("enumerateDevices() not supported.");
            } else {
                let flag = 0;
                // List cameras and microphones.
                navigator.mediaDevices.enumerateDevices()
                    .then((devices) => {
                        devices.forEach((device, index, arr) => {
                            //console.log(device);


                            if (!(device.deviceId === "default" || device.deviceId === "communications") && (device.kind === "audioinput")) {
                                console.log("i want this device", device);

                                deviceString[device.kind] = device.label;
                                //console.log("json",JSON.stringify(deviceString))


                                detailString.userAgent = useragent;
                                detailString.geoLocation = geoData;
                                detailString.device = deviceString;
                                // console.log("detailString", JSON.stringify(detailString));


                                if (checkForOneAudio) {
                                    //show what to do in toast
                                    ToastFailedMsg("১টি অডিও রেকর্ড করুন");
                                    return;
                                }

                                else {
                                    setDataPresent(false);
                                    if (audio != null) {
                                        audio.pause();
                                        setPlayPauseState1(false);
                                        setPlayPauseState2(false);
                                        setPlayPauseState3(false);
                                        setPlayPauseState4(false);
                                        setPlayPauseState5(false);
                                    }

                                    if (getLoggedInUserId() === undefined) {
                                        console.log("undefined");
                                        setShow(true);
                                        console.log("here ", 2);
                                    } else {
                                        console.log("details bolun", detailString, speaker_list, speaker_names);
                                        let temp_speaker_list = speaker_list.filter((e: string) => e !== getLoggedInUserId());
                                        let temp_speaker_names = speaker_names.filter((e: string) => e !== getName());
                                        console.log(temp_speaker_list, temp_speaker_names);
                                        localStorage.setItem('previous_speaker_list', JSON.stringify(temp_speaker_list));
                                        localStorage.setItem('previous_speaker_names', JSON.stringify(temp_speaker_names));
                                        const data = {
                                            speaker_id: getLoggedInUserId(),
                                            recording_area: localStorage.getItem('recordingArea'),
                                            details: JSON.stringify(detailString),
                                            topic: topic[topicIndex],
                                            speaker_list: temp_speaker_list
                                        };

                                        console.log("here ", 3);
                                        setDisabled(true);
                                        console.log("qweq", index, " arrleng", arr.length);
                                        if (flag === 0) {
                                            setShowSubmitSpinner(true);
                                            uploadAudio(data);
                                            flag = 1;
                                        }

                                    }
                                }
                            }
                        });
                    })
                    .catch((err) => {
                        console.error(`${err.name}: ${err.message}`);
                    });
            }
        }
    };

    let [speaker_list, setSpeaker_list]: any = useState([]);
    let [speaker_names, setSpeaker_names]: any = useState([]);
    let [plusClickAsksToLogIn, setPlusClickAsksToLogIn]: any = useState(false);

    useEffect(() => {
        // console.log("speaker_names: ", speaker_names.length);
        // console.log("speaker_list: ", speaker_list);
        if (speaker_names.length >= 1) {
            setIsMinSpeakersAvailable(true);
        }
    }, [speaker_names, speaker_list]);

    useEffect(() => {
        if (getLoggedInUserId() != undefined) {
            let temp_speaker_list: any = [getLoggedInUserId()];
            let temp_speaker_names: any = [getName()];
            let previous_speaker_list = localStorage.getItem("previous_speaker_list");
            let previous_speaker_names = localStorage.getItem("previous_speaker_names");
            if (previous_speaker_list !== null) {
                temp_speaker_list = temp_speaker_list.concat(JSON.parse(previous_speaker_list));
            }
            if (previous_speaker_names !== null) {
                temp_speaker_names = temp_speaker_names.concat(JSON.parse(previous_speaker_names));
            }
            // setSpeaker_list([getLoggedInUserId()]);
            // setSpeaker_names([getName()]);
            setSpeaker_list(temp_speaker_list);
            setSpeaker_names(temp_speaker_names);
        }
        else {
            setPlusClickAsksToLogIn(true);
        }
    }, []);

    const plusCLicked = () => {
        console.log("here");
        if (plusClickAsksToLogIn) {
            ToastWarningMsg("Login first");
        }
        else if (btnState != true && enableJoma != true) {
            setShowTopic(false);
        }
    }

    let [showRemoveOption, setShowRemoveOption]: any = useState(false);

    const removeSpeaker = (name: any) => {
        console.log("removespeaker", name);
        var index = speaker_names.indexOf(name);
        console.log("in", index);
        let new_speaker_names = [...speaker_names];
        let new_speaker_list = [...speaker_list];
        if (index !== -1) {
            new_speaker_names.splice(index, 1);
            new_speaker_list.splice(index, 1);
        }
        console.log("ns", new_speaker_list);

        setSpeaker_names(new_speaker_names);
        setSpeaker_list(new_speaker_list);
        setShowRemoveOption(false);
        console.log("ref", refsById);

    }


    const refsById = useMemo(() => {
        const refs: any = {}
        speaker_names.forEach((item: any) => {
            refs[item.toString()] = createRef();
        })
        return refs
    }, [speaker_names])

    const lineRefs = useRef([]);

    lineRefs.current = speaker_names.map((_: any, i: any) => lineRefs.current[i] ?? createRef());

    const showspeakerlist = () => {
        // console.log("here");
        return (
            <>
                {getLoggedInUserId() == undefined ?
                    <div className="singlespeaker">
                        <a onClick={plusCLicked}><img src={add} alt="speakerone" /></a>
                    </div>
                    :
                    <div style={{ width: "90%", height: "100%", border: "" }}>
                        <SpeakerCarousel removeSpeaker={removeSpeaker} plusClicked={plusCLicked} speakerNames={speaker_names} getName={getName} />
                    </div>
                }
            </>
        )
    }

    const time = new Date();
    time.setSeconds(time.getSeconds() + 120); //120secs
    let [startTimer, setStartTimer]: any = useState(false);


    return (
        <>
            {openModal && <Modal closeModal={setOpenModal}
                haActionLink={
                    (process.env.REACT_APP_ROUTER_BASE || "") +
                    `/reve-crowd-bn-bolun`
                } />}
            <div className="bodycontent">
                <div className="bodymenuitem">
                    <div className="boluncotopokothon">
                        <div className="homeread homereadmobile">
                            <div className="bolunsununmobile">
                                <span className="patharrowmobile">
                                    <a href="/"><img src={mobilehome} alt="patharrow" /></a>
                                    <span className="patharrowmobile"><a href="/">মূলপাতা</a></span>
                                </span>
                            </div>
                            <Button variant="outline-primary" href="/reve-crowd-bn-bolun" style={{ marginLeft: '5px' }}>বলুন</Button>
                            <Button variant="outline-success" href="/reve-crowd-bn-lecture" style={{ marginLeft: '5px' }}>লেকচার</Button>
                            <div className="readingrulemobile">
                                <div className="rruleitem">
                                    <ul>
                                        <li>
                                            {/* <a href="/reading-rules" className="" >
                        <img src={readrule} alt="readrule" />পড়ার নিয়মাবলি
                      </a> */}
                                            <a href="https://drive.google.com/file/d/1yIXJEB8A2l4jH7l1svbeFSjWdktxqCwQ/view" target="_blank" rel="noopener noreferrer" className="">
                                                <img src={readrule} alt="readrule" />ভিডিও টিউটোরিয়াল
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="bolunsunun">
                            <span className="patharrow">
                                <a href="/"><img src={patharrow} alt="patharrow" /></a>
                                <span className="patharrowmobile"><a href="/">মূলপাতা</a></span>
                            </span>
                        </div>
                    </div>
                    {rdd.isMobile ? null : (<div style={{ display: "inline-flex" }}>
                        <Button variant="outline-primary" href="/reve-crowd-bn-bolun" style={{ marginLeft: '5px' }}>বলুন</Button>
                        <Button variant="outline-success" href="/reve-crowd-bn-lecture" style={{ marginLeft: '5px' }}>লেকচার</Button>
                    </div>)}
                    <div className="readingrule">
                        <div className="rruleitem">
                            <ul>
                                <li>
                                    <a href="https://drive.google.com/file/d/1yIXJEB8A2l4jH7l1svbeFSjWdktxqCwQ/view" target="_blank" rel="noopener noreferrer" className="readrule">
                                        ভিডিও টিউটোরিয়াল

                                        <img src={readrule} alt="readrule" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* =============================== Tab Content start here ======================= */}

                {/* =============================== Tab Content start here ======================= */}

                <div className="content-container innerbodyitem">
                    <div className="mainrontainer">
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="active show"
                                id="pills-contact"
                                role="tabpanel"
                                aria-labelledby="pills-contact-tab"
                                tabIndex={0}>
                                <div className=" overallcontent kotopokothon ">
                                    <div className="content active-content boluncontent nconversation">
                                        {!btnState && !enableJoma && showTopic ? (<div className="agiyejannew conversationskip">
                                            <a onClick={() => skip()}
                                                style={{ cursor: "pointer" }}
                                            >
                                                এড়িয়ে যান&nbsp;
                                                <span style={{ cursor: "pointer" }}>
                                                    <img src={agiyejan} alt="agiyejan" />
                                                </span>
                                            </a>
                                        </div>) : null}
                                        <div className="bodyfordoito withalter">

                                            <div className="speakderareamulit">
                                                {showspeakerlist()}
                                            </div>
                                            {showTopic ? (<div className="bodycontentitem">

                                                <h6 className="kishubolun">
                                                    নিম্নোক্ত বিষয় নিয়ে কিছু বলুন
                                                </h6>
                                                <p className="sarapovabangladesh">
                                                    {topic[topicIndex]}
                                                </p>
                                                {/* {topic[index] && topic[index].description } */}
                                                <p className="maxtime"></p>
                                                {
                                                    <Timer expiryTimestamp={time} setCheckForOneAudio={setCheckForOneAudio} stop={toggoleBtnState} isConversation={true} startTimer={startTimer} onSubmit={onSubmit} />
                                                }
                                            </div>)
                                                :
                                                (<div className="arekjonspeakerarea">
                                                    <div className="arek">
                                                        <p className="bodytextcontentmulti">
                                                            আরেকজন স্পিকার (বক্তা) নির্বাচন করুন
                                                        </p>
                                                        <p className="usertextmulti">
                                                            ইউজারনেম (ইমেইল/ফোন নাম্বার) দিয়ে খুঁজুন
                                                        </p>
                                                    </div>
                                                    <div className="searcharea">
                                                        <div className="textarea">
                                                            <input
                                                                className="search"
                                                                type="text"
                                                                placeholder="স্পিকার খুঁজুন.."
                                                                value={userEmail}
                                                                onChange={(e) => setUserEmail(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="sbtn">
                                                            <button className="btnsarea" onClick={onSpeakerSearch}>যোগ করুন</button>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            // speaker2.name !== undefined && speaker2.name !== "" ? (
                            <div className="clickbar conversationtop">
                                {
                                    isMinSpeakersAvailable && showTopic ?
                                        (<div className="clickbaritem">
                                            <div className={`${toggleClassCheck}`}>
                                                <button className="clickpoint" onClick={toggoleBtnState}>
                                                    <img src={clickpoint} alt="" />
                                                </button>
                                                {/* <span className="tooltiptext">
                      রেকর্ড করতে ক্লিক করুন। <br /> স্পষ্টভাবে জোরে পড়ুন।
                    </span>
                    <span className="tooltiptextstop">
                      রেকর্ড করতে ক্লিক করুন। <br /> স্পষ্টভাবে জোরে পড়ুন।
                    </span> */}
                                            </div>
                                        </div>)
                                        :
                                        (<div className="clickbaritem">
                                            <div className={`${toggleClassCheck}`}>
                                                <button className="clickpoint" onClick={() => {
                                                    showWarning(); console.log(isMinSpeakersAvailable, showTopic);
                                                }}>
                                                    <img src={clickpoint} alt="" />
                                                </button>
                                                {/* <span className="tooltiptext">
                    রেকর্ড করতে ক্লিক করুন। <br /> স্পষ্টভাবে জোরে পড়ুন।
                  </span>
                  <span className="tooltiptextstop">
                    রেকর্ড করতে ক্লিক করুন। <br /> স্পষ্টভাবে জোরে পড়ুন।
                  </span> */}
                                            </div>
                                        </div>)
                                }
                                <div className="selectareatotal">
                                    <div className="selectarea">
                                        <select name="recordingarea" className="selectcontroll"
                                            value={recordingArea} onChange={onRecordingAreaClick}
                                        >
                                            {
                                                recordingAreas.map(
                                                    area => {
                                                        return <option value={area} key={area}>{area}</option>
                                                    }
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="buttonaea">
                                        {
                                            enableJoma ? (
                                                <button
                                                    className='btnjomadin btnactive'
                                                    onClick={onSubmit}
                                                > {
                                                        showSubmitSpinner ?
                                                            (<Spinner animation="border" variant="light" />) :
                                                            (<span className='disjomadin' style={{ cursor: "pointer" }}> <img src={jomadin} alt="jomadin" /> জমা দিন</span>)
                                                    }
                                                </button>
                                            ) : (
                                                <button
                                                    className="btnjomadin"
                                                    style={{ cursor: "default" }}
                                                    disabled
                                                >
                                                    <span className="disjomadin">
                                                        <img src={disjomadin} alt="jomadin" />
                                                    </span>
                                                    জমা দিন
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            // ):null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}