import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.scss";
import { ReactNotifications } from "react-notifications-component";
import { isLoggedIn } from "./common/http";
import SignUpPage from "./pages/crowd-source/SignUp.page";
import LogInPage from "./pages/crowd-source/LogIn.page";
import AboutPage from "./pages/crowd-source/About.page";
import CrowdDashBoard from "./pages/crowd-source/Dashboard.page";
import ReadingRulesPage from "./pages/crowd-source/ReadingRules.page";
import ListeningRulesPage from "./pages/crowd-source/ListeningRules.page";
import Userdashboard from "./pages/crowd-source/user-dashboard/Userdashboard";
import IndexBolun from "./pages/crowd-source/IndexBolun";
import IndexShunun from "./pages/crowd-source/IndexShunun";
import IndexLecture from "./pages/crowd-source/IndexLecture";
import IndexKothopokothonMany from "./pages/crowd-source/IndexKothopokothonMany";

function App() {
  const [loginStatus, setLoginStatus] = useState({
    loginStatus: isLoggedIn(),
  });

  useEffect(() => {
    // console.log('isLoggedIn', loginStatus);
  }, []);

  return (
    <div className="App">
      <ReactNotifications />
      <Router basename={process.env.REACT_APP_ROUTER_BASE || ""}>
        <Routes>
          <Route path="/" element={<CrowdDashBoard />} />
          <Route path="/reve-crowd-bn/" element={<IndexBolun />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/log-in" element={<LogInPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/reading-rules" element={<ReadingRulesPage />} />
          <Route path="/listening-rules" element={<ListeningRulesPage />} />
          <Route path="/user-dashboard" element={<Userdashboard />} />
          <Route path="/reve-crowd-bn-bolun" element={<IndexBolun />} />
          <Route path="/reve-crowd-bn-shunun/" element={<IndexShunun />} />
          <Route path="/reve-crowd-bn-conversation-multi" element={<IndexKothopokothonMany />} />
          <Route path="/reve-crowd-bn-lecture" element={<IndexLecture />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
