import React, { useState, useEffect, useRef, useMemo, createRef } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import mobilehome from "./images/stts/home.png";
import readrule from "./images/stts/readrule.png";
import { FcNext, FcPrevious } from "react-icons/fc";
import clickpoint from "./images/stts/clickhover.png";
import disjomadin from "./images/stts/disjomadin.png";
import agiyejan from "./images/stts/agiyejan.png";
import jomadin from "./images/stts/jomadin.png";
import reload from "./images/stts/reload.png"
import porasunte from "./images/stts/porasunte.png"
import pauseicon from "./images/stts/pause icon.png";
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import SplitButton from 'react-bootstrap/SplitButton';
import styles from './Lecture.module.css';
import { getLocalRecordingArea, getLoggedInUserId, getName, setLocalRecordingArea } from '../../common/http';
import { TestWarning, ToastFailedMsg, ToastSuccessMsg, ToastWarningMsg } from '../../common/toast';
import { getUserPermissionFlag, getLectureSlides } from "./crowd_source.service";
import useRecorder from '../../common/AudioRecorder';
import * as rdd from 'react-device-detect';
import { useGeolocated } from 'react-geolocated';
import { EventKey } from '@restart/ui/esm/types';
import Modal from 'react-bootstrap/Modal';
import Timer from "./Timer";

export default function LectureComponent(props: any) {
    let [
        audioURL,
        setAudioURL,
        isRecording,
        startRecording,
        stopRecording,
        pauseRecording,
        resumeRecording,
        uploadAudio,
        checkRecording,
        count,
        audios,
        reRecordingState,
        setRecordingState,
        reRecordingNumber,
        setRerecordingNumber,
        setScriptId,
        scriptId,
    ]: any = useRecorder(props = { type: 2 });

    let [isDesktop, setDesktop] = useState(window.innerWidth > 1450);
    let [isAudioRecordededForSlide, setIsAudioRecordedForSlide]: any = useState([]);
    let [startTime, setStartTime]: any = useState(Date.now());
    let [ToggleState, setToggleState] = useState(1);
    let [btnState, setBtnState] = useState(false);
    let toggleClassCheck = btnState ? "clickstop" : "clickimg";
    let [recorderPermission, setRecorderPermission]: any = useState(false);
    let [dataPresent, setDataPresent]: any = useState(false);
    let userIdRef = useRef(getLoggedInUserId());

    const handler = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = "";
    };

    useEffect(() => {
        // the handler for actually showing the prompt
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload

        // if the form is NOT unchanged, then set the onbeforeunload
        if (dataPresent) {
            window.addEventListener("beforeunload", handler);
            // clean it up, if the dirty state changes
            return () => {
                window.removeEventListener("beforeunload", handler);
            };
        }
        // since this is not dirty, don't do anything
        return () => { };
    }, [dataPresent]);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1450);
    };

    const getActiveClass = (index: any, className: any) =>
        ToggleState === index ? className : "";

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    useEffect(() => {
        if(localStorage.getItem('timerExpired') && parseInt(localStorage.getItem('timerExpired')!) == 1){
            getLecture(true);
        } else {
            getLecture();
        }
        
        setRecordingArea(getLocalRecordingArea());
        console.log("Speaker Id " + userIdRef.current);

        console.log("local recording area: ", getLocalRecordingArea())
        console.log("recording area: ", recordingArea);

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(function (stream) {
                if (count > 0 && !recorderPermission) {
                    window.location.reload();
                }
                setRecorderPermission(true);
                console.log("MIC Found");
            })
            .catch(function (err) {
                setRecorderPermission(false);
                ToastWarningMsg("Check your microphone permission");
                console.log("No mic for you!");
            });

        localStorage.removeItem('latest_duration');
        localStorage.removeItem('latest_word_count');
        localStorage.removeItem('latest_syllable_count');
        localStorage.removeItem('latest_phoneme_count');
        localStorage.removeItem('recorded_scripts_length');

        // const interval = setInterval(() => setShowHelp(false), 3000);
        // return () => {
        //     clearInterval(interval);
        // };
        const interval = setInterval(() => setShowHelp(false), 3000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    let [userPermissionChecked, setUserPermissionChecked]: any = useState(false);

    const toggoleBtnState = async () => {
        console.log(isAudioRecordededForSlide[slideNumber])
        if (getLoggedInUserId() === undefined) {
            ToastWarningMsg("নিবন্ধন বা লগইন করুন।");
        }
        else if (!recorderPermission) {
            ToastWarningMsg("Check your microphone permission");
            console.log("No mic for you!");
        }
        else if (!apiCallIsSuccess) {
            ToastFailedMsg("পর্যাপ্ত স্লাইড নেই। অনুগ্রহ করে একটু পরে আবার চেষ্টা করুন।");
        }
        else if ((isAudioRecordededForSlide[slideNumber] == false) || (isAudioRecordededForSlide[slideNumber] == undefined)) {
            // setBtnState(!btnState);
            if (userPermissionChecked) {
                setBtnState(!btnState);
            }
            else {
                let res = await getUserPermissionFlag({ "userId": getLoggedInUserId() });
                if (res.status === 200 || res.status === 201) {
                    let { bolun_lecture_flag } = await res.json();
                    if (bolun_lecture_flag == 1) {
                        //resume as before
                        console.log("bolun_lecture_flag", bolun_lecture_flag);
                        setBtnState(!btnState);
                        setUserPermissionChecked(true);
                        setShowHelp(true);
                    }
                    else {
                        console.log("bolun_lecture_flag", bolun_lecture_flag);
                        ToastFailedMsg("এই অংশে আপনার রেকর্ডের সময়সীমা শেষ। অন্য অংশে ভয়েস রেকর্ড করুন।");
                    }
                }
            }
        }
        else {
            ToastWarningMsg("আপনি এই স্লাইডের জন্য রেকর্ড করেছেন। অনুগ্রহ করে এগিয়ে যান।");
            setShowHelp(true);
        }
    }

    useEffect(() => {
        toggolePlayPauseClick();
    }, [btnState]);
    const [playState, setPlayState]: any = useState('clickstop');
    const toggolePlayPauseClick = () => {
        setPlayState(btnState ? 'clickimg' : 'clickstop');
    }

    useEffect(() => {
        console.log("playState: ", playState);

        if (playState === 'clickstop') {
            if (recorderPermission) {
                stop();
            }
        } else {
            if (recorderPermission) {
                console.log("Start Recording ... !");
                startRecording("start");
                setStartTime(Date.now());
            }
        }
    }, [playState]);

    const stop = () => {
        console.log("In Lecture Component ... !");
        localStorage.setItem("latest_word_count", String(slideDetails[slideNumber].word_count));
        // localStorage.setItem("latest_syllable_count", String(slideDetails[slideNumber].syllable_count));
        localStorage.setItem("latest_phoneme_count", String(slideDetails[slideNumber].phoneme_count));

        let currentAudioDuration = (Date.now() - startTime) / 1000;
        localStorage.setItem("latest_duration", String(currentAudioDuration));
        stopRecording("stop");

        console.log("duration of current audio: ", currentAudioDuration);
        let words_per_second = slideDetails[slideNumber].word_count / currentAudioDuration;
        // let syllables_per_second = slideDetails[slideNumber].syllable_count / currentAudioDuration;
        let phonemes_per_second = slideDetails[slideNumber].phoneme_count / currentAudioDuration;

        console.log("wps pps:", words_per_second, phonemes_per_second);

        if (currentAudioDuration >= 1 && (phonemes_per_second >= 5 && phonemes_per_second <= 15)) {
            setDataPresent(true);
            let tempIsAudioRecordedForSlide: any = isAudioRecordededForSlide;
            tempIsAudioRecordedForSlide[slideNumber] = true;
            setIsAudioRecordedForSlide(tempIsAudioRecordedForSlide);
            if (slideNumber < slides.length - 1 && !reRecordIsRequested) {
                setSlideNumber(slideNumber + 1);
                setTraversedSlides([...traversedSlides, slides[slideNumber + 1]]);
            }
            else if (reRecordIsRequested) {
                setReRecordIsRequested(false);
            }
            else {
                // setEnableJoma(true);
                // ToastSuccessMsg("আপনি সব স্লাইড পড়েছেন। অনুগ্রহ করে জমা দিন");
                
                ToastWarningMsg("আমরা এই মুহূর্তে নতুন ডেটা কালেকশন করছি না।")
            }
        }
        else {
            // ToastFailedMsg("বেশি দ্রুত বা ধীরে পড়েছেন!");

        }
    };

    let [recordingArea, setRecordingArea]: any = useState();
    const [showSubmitSpinner, setShowSubmitSpinner]: any = useState(false);
    const [enableJoma, setEnableJoma]: any = useState(false);
    const recordingAreas = [
        "রুমের মধ্যে",
        "রুমের বাইরে",
        "মার্কেটে",
        "মাঠে",
        "রাস্তায়",
        "উন্মুক্ত স্থানে",
        "অন্যান্য জনবহুল স্থানে"
    ]


    let [slideNumber, setSlideNumber]: any = useState(0);
    let [slides, setSlides]: any = useState([]);
    let [traversedSlides, setTraversedSlides]: any = useState([]);
    let [parentId, setParentId]: any = useState();
    let [slideDetails, setSlideDetails]: any = useState();
    let [apiCallIsSuccess, setApiCallIsSuccess]: any = useState(true);

    const getLecture = async (skipped:boolean = false) => {
        let tempIsAudioRecordedForSlide: any = [];
        for (let i = 0; i < slides.length; i++) {
            tempIsAudioRecordedForSlide[i] = false;
        }
        setIsAudioRecordedForSlide(tempIsAudioRecordedForSlide);
        let res = await getLectureSlides(getLoggedInUserId(), skipped);

        if (res.status === 200 || res.status === 201) {
            try {
                let { data, parent_id, details } = await res.json();
                console.log("parent_id", parent_id);
                setSlides(data);
                setTraversedSlides([data[0]]);
                setParentId(parent_id);
                setSlideDetails(details);
                localStorage.removeItem('timerExpired')
            }
            catch (err) {
                console.log(err);
            }
        }
        else {
            setApiCallIsSuccess(false);
            ToastFailedMsg("পর্যাপ্ত স্লাইড নেই। অনুগ্রহ করে একটু পরে আবার চেষ্টা করুন।");
        }
    }


    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 5000,
        });

    const onSubmit = async () => {
        if (!showSubmitSpinner) {
            console.log(rdd.isAndroid, rdd);
            let useragent: any = {};
            let geoData: any = {};
            let deviceString: any = {};


            useragent.isDesktop = rdd.isDesktop;
            useragent.mobileVendor = rdd.mobileVendor;
            useragent.mobileModel = rdd.mobileModel;
            useragent.osName = rdd.osName;
            useragent.osVersion = rdd.osVersion;
            useragent.browserName = rdd.browserName;
            useragent.fullBrowserVersion = rdd.fullBrowserVersion;

            //let useragentString= JSON.stringify(useragent);
            //console.log(useragentString);

            let geoDataString: any;

            if (!isGeolocationAvailable) {
                console.log("Your browser does not support Geolocation");
            } else if (!isGeolocationEnabled) {
                console.log("Geolocation is not enabled");
            } else {
                console.log("Latitude !!!", coords?.latitude);

                geoData.latitude = coords?.latitude;
                geoData.longitude = coords?.longitude;
                geoData.altitude = coords?.altitude;
                geoData.heading = coords?.heading;
                geoData.speed = coords?.speed;

                //geoDataString=JSON.stringify(geoData);
                //console.log(geoDataString);
            }


            let detailString: any = {};
            if (!navigator.mediaDevices?.enumerateDevices) {
                console.log("enumerateDevices() not supported.");
            } else {
                let flag = 0;
                // List cameras and microphones.
                navigator.mediaDevices.enumerateDevices()
                    .then((devices) => {
                        devices.forEach((device, index, arr) => {
                            //console.log(device);
                            if (!(device.deviceId === "default" || device.deviceId === "communications") && (device.kind === "audioinput")) {
                                console.log("i want this device", device);

                                deviceString[device.kind] = device.label;
                                //console.log("json",JSON.stringify(deviceString))


                                detailString.userAgent = useragent;
                                detailString.geoLocation = geoData;
                                detailString.device = deviceString;
                                // console.log("detailString", JSON.stringify(detailString));

                                setDataPresent(false);
                                const data = {
                                    speaker_id: getLoggedInUserId(),
                                    recording_area: localStorage.getItem('recordingArea'),
                                    details: JSON.stringify(detailString),
                                    // topic: topic[topicIndex],
                                    // speaker_list: speaker_list
                                    parent_id: parentId
                                };

                                if (flag === 0) {
                                    setShowSubmitSpinner(true);
                                    uploadAudio(data);
                                    flag = 1;
                                }
                            }
                        });
                    })
                    .catch((err) => {
                        console.error(`${err.name}: ${err.message}`);
                    });
            }
        }
    };

    const changeSlideFromDropDown = (event: any) => {
        if (reRecordIsRequested) {
            ToastWarningMsg("পুনরায় রেকর্ড করুন");
        }
        else {
            setSlideNumber(event);
        }
    }

    const onRecordingAreaClick = (event: any) => {
        console.log("Recording Area ", event.target.value);
        if (event.target.value) {
            setLocalRecordingArea(event.target.value);
            setRecordingArea(event.target.value);
        }
    };

    const EnToBn = (n: any) => n.replace(/\d/g, (d: any) => "০১২৩৪৫৬৭৮৯"[d]);

    let [audioIsPlaying, setAudioIsPlaying]: any = useState(false);
    const playAudio = (event: any) => {
        setAudioIsPlaying(true);
        let audio = new Audio(audios[event]);
        audio.play();
        audio.addEventListener("ended", () => { setAudioIsPlaying(false) });
    };

    let [reRecordIsRequested, setReRecordIsRequested]: any = useState(false);
    const reRecord = (event: any) => {
        let tempIsAudioRecordedForSlide: any = isAudioRecordededForSlide;
        tempIsAudioRecordedForSlide[slideNumber] = false;
        setIsAudioRecordedForSlide(tempIsAudioRecordedForSlide);
        setReRecordIsRequested(true);
        setRecordingState(true);
        setRerecordingNumber(event);
    }

    const skip = () => {
        setTraversedSlides([]);
        getLecture(true);
    }

    const [show, setShow]: any = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const time = new Date();
    time.setSeconds(time.getSeconds() + 1800);

    const [showHelp, setShowHelp]: any = useState(true);

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                {/* <Modal.Header closeButton>
                    <Modal.Title>
                        আপনি কি এই স্পিকারকে সরানোর বিষয়ে নিশ্চিত?</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <h5>আপনি কি নিশ্চিত আপনি পুনরায় রেকর্ড করতে চান?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        ফিরে যান
                    </Button>
                    <Button variant="success" onClick={() => { reRecord(`audio${slideNumber + 1}`); handleClose(); }}>
                        হ্যাঁ আমি নিশ্চিত
                    </Button>
                </Modal.Footer>
            </Modal>
            <Container>
                <Row className="m-1">
                    <Col className="pt-2 d-flex justify-content-start" xs={3} md={2}>
                        <span>
                            <a href="/"><img src={mobilehome} alt="patharrow" /></a>
                            <span className="patharrowmobile"><a href="/">মূলপাতা</a></span>
                        </span>
                    </Col>
                    <Col className="d-flex justify-content-end" xs={6} md={6}>
                        <div>
                            <Button className="" variant="outline-primary" href="/reve-crowd-bn-bolun" style={{ marginLeft: '5px' }}>বলুন</Button>
                        </div>
                        <div>
                            <Button className="" variant="outline-success" href="/reve-crowd-bn-conversation-multi" style={{ marginLeft: '5px' }}>কথোপকথন</Button>
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end pt-2" xs={3} md={4}>
                        <div className="rruleitem">
                            <ul>
                                <li>
                                    <a href="/reading-rules" className=''>
                                        <img src={readrule} alt="readrule" />পড়ার নিয়মাবলি
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={10}>
                        <div style={{ border: "0px solid green", position: "relative" }}
                            className={`content ${getActiveClass(
                                1,
                                "active-content"
                            )} boluncontent px-0`}>
                            <Row className='w-100'>
                                <Col className="d-flex justify-content-start pt-2">
                                    <Timer expiryTimestamp={time} dataPresent={dataPresent} setDataPresent={setDataPresent} isLecture={true} />
                                </Col>
                                <Col className="d-flex justify-content-end pt-2">{!dataPresent && !isRecording? (
                                    <div>
                                        <a
                                            onClick={() => skip()}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <span style={{ cursor: "pointer", color: "red" }}>
                                                এড়িয়ে যান&nbsp;
                                                <img src={agiyejan} alt="agiyejan" />
                                            </span>
                                        </a>
                                    </div>) : null}</Col>
                            </Row>
                            <Row className='w-100'>
                                <Col className="m-auto" xs={2} md={2}>
                                    {/* <div style={{ position: "absolute", top: "5px" }}>
                                        <Timer expiryTimestamp={time} dataPresent={dataPresent} setDataPresent={setDataPresent} />
                                    </div> */}
                                    <button style={{ border: "none" }} disabled={reRecordIsRequested || isRecording} onClick={() => { if (slideNumber != 0) { setSlideNumber(slideNumber - 1) } }}><FcPrevious /></button>
                                </Col>

                                <Col className="bg m-auto" xs={8} md={8}>
                                    <div className={styles.bodytextcontent}>
                                        <div className={styles.setmargin}>
                                            {slides[slideNumber]}
                                        </div>
                                    </div>
                                </Col>
                                <Col className="m-auto" xs={2} md={2}>
                                    {/* <div className="m-0 p-0" style={{ position: "absolute", top: "5px", textAlign: "right", width: "inherit" }}>
                                        {!dataPresent ? (
                                            <div >
                                                <a
                                                    onClick={() => skip()}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <span style={{ cursor: "pointer", color: "red" }}>
                                                        এড়িয়ে যান&nbsp;
                                                        <img src={agiyejan} alt="agiyejan" />
                                                    </span>
                                                </a>
                                            </div>) : null}
                                    </div> */}
                                    <button style={{ border: "none" }} disabled={reRecordIsRequested || isRecording} onClick={() => { if (slideNumber != traversedSlides.length - 1) { setSlideNumber(slideNumber + 1) } }}><FcNext /></button>
                                </Col>
                                {
                                    isDesktop ? null :
                                        <Col >
                                            <div className="clickbaritem">
                                                <div className={`${toggleClassCheck}`}>
                                                    <button className="clickpoint" onClick={toggoleBtnState}>
                                                        <img src={clickpoint} alt="" />
                                                    </button>
                                                    {toggleClassCheck !== "clickstop" ?<span className={`${showHelp ? 'tooltiptext' : 'hidecontent'}`}>
                                                        রেকর্ড করতে ক্লিক করুন। <br /> স্পষ্টভাবে জোরে পড়ুন।
                                                    </span>:<span className={`${showHelp ? 'tooltiptextstop' : 'hidecontent'}`}>পড়া শেষে ক্লিক করুন।</span>}
                                                </div>
                                            </div>
                                        </Col>
                                }
                            </Row>
                            <Row className='mt-1'>
                                <Col xs={12} md={12}>
                                    <div style={{ color: "blue" }}>
                                        স্লাইড {EnToBn(String(slideNumber + 1))} / {EnToBn(String(slides.length))}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col md={2} className="my-auto">
                        {isAudioRecordededForSlide[slideNumber] ?
                            (<div className="singleitem fullitem">
                                <ul>
                                    <li>
                                        <button className='playstart'
                                            onClick={() => playAudio(`audio${slideNumber + 1}`)}
                                            disabled={audioIsPlaying || reRecordIsRequested}
                                        >
                                            {/* <img src={porasunte} alt="porasunte" /> </button> */}
                                            {audioIsPlaying ?
                                                <img src={pauseicon} alt="porasunte" /> :
                                                <img src={porasunte} alt="porasunte" />
                                            }

                                        </button>
                                    </li>
                                    <li> <button className='playreload'
                                        onClick={() => setShow(true)}
                                    // disabled={playButtonDisable || reRecordingState}
                                    >
                                        <img src={reload} alt="porasunte" />
                                    </button> </li>
                                    <li> <span className='countitem'>{slideNumber + 1}</span></li>
                                </ul>
                            </div>)
                            :
                            (<div className="singleitem">
                                <ul>
                                    <li> <span className='recordstart'>রেকর্ডিং শুরু করুন</span> </li>
                                    <li> <span className='countitem'>{slideNumber + 1}</span></li>
                                </ul>
                            </div>)
                        }
                        <div className={styles.singleitem}>
                            <SplitButton
                                key="up"
                                id={`dropdown-button-drop-down`}
                                drop='end'
                                variant="primary"
                                title={slideNumber + 1}
                                align="end"
                                disabled={isRecording}
                            >
                                <div style={{ height: "120px", overflowY: "auto" }}>
                                    {
                                        traversedSlides.map((slide: React.Key | null | undefined, i: any) => { return <Dropdown.Item key={slide} eventKey={i} onClick={() => changeSlideFromDropDown(i)}>{i + 1}</Dropdown.Item> })
                                    }
                                </div>
                            </SplitButton>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        isDesktop ?
                            <Col md={10}>
                                <div className="clickbaritem">
                                    <div className={`${toggleClassCheck}`}>
                                        <button className="clickpoint" onClick={toggoleBtnState}>
                                            <img src={clickpoint} alt="" />
                                        </button>
                                        <span className={`${showHelp ? 'tooltiptext' : 'hidecontent'}`}>
                                            রেকর্ড করতে ক্লিক করুন। <br /> স্পষ্টভাবে জোরে পড়ুন।
                                        </span>
                                    </div>
                                </div>
                            </Col> : null
                    }
                    <Col>
                        <div className="selectarea">
                            <select name="recordingarea" className="selectcontroll"
                                value={recordingArea} onChange={onRecordingAreaClick}
                            >
                                {
                                    recordingAreas.map(
                                        area => {
                                            return <option value={area} key={area}>{area}</option>
                                        }
                                    )
                                }
                            </select>
                        </div>
                        <div className="buttonaea">
                            {
                                enableJoma ? (
                                    <button className='btnjomadin btnactive' onClick={onSubmit} disabled={showSubmitSpinner}> {
                                        showSubmitSpinner ?
                                            (<Spinner animation="border" variant="light"/>) :
                                            (<span className='disjomadin' style={{ cursor: "pointer" }}> <img src={jomadin} alt="jomadin" /> জমা দিন</span>)
                                    }
                                    </button>
                                ) : (
                                    <button className="btnjomadin mb-5" style={{ cursor: "default" }} disabled>
                                        <span className="disjomadin">
                                            <img src={disjomadin} alt="jomadin" />
                                        </span>
                                        জমা দিন
                                    </button>
                                )
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}