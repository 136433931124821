import getBlobDuration from "get-blob-duration";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { uploadCrowdSourceAudio, submitConvData, submitLectureData } from "../pages/upload/upload.service";
import { getLoggedInUserId } from "./http";
import { ToastFailedMsg, ToastSuccessMsg } from "./toast";
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { v4 as uuidv4 } from 'uuid';

const useRecorder = (props: any) => {
    const [audioURL, setAudioURL] = useState("");
    const [isRecording, setIsRecording]: any = useState(false);
    const [recordingAction, setRecordingAction]: any = useState('');
    const [recorder, setRecorder]: any = useState(null);
    const [audioData, setAudioData]: any = useState({});
    const [wavData, setWavData]: any = useState();
    const [audiodata, setAudio]: any = useState();
    const [checkRecording, setCheckRecording]: any = useState(false);
    const [count, setCount]: any = useState(0);
    const [reRecordingState, setRerecordingState]: any = useState(false);
    const [reRecordingNumber, setRerecordingNumber]: any = useState("");
    let [scriptId, setScriptId]: any = useState([]);
    const navigate = useNavigate();
    const [permission, setPermission]: any = useState(true);
    const [denied, setDenied]: any = useState(false);
    const [format, setFormat]: any = useState(null);

    const [audios, setAudios]: any = useState({
        audio1: "",
        audio2: "",
        audio3: "",
        audio4: "",
        audio5: ""
    });

    const [recordedData, setRecordedData]: any = useState({
        audio1: null,
        audio2: null,
        audio3: null,
        audio4: null,
        audio5: null
    })


    const [audioTime, setAudioTime]: any = useState({
        time1: 0,
        time2: 0,
        time3: 0,
        time4: 0,
        time5: 0
    })

    useEffect(() => {
        // localStorage.removeItem('latest_duration');
        // localStorage.removeItem('latest_word_count');
        // localStorage.removeItem('latest_syllable_count');
        // localStorage.removeItem('latest_phoneme_count');
        // localStorage.removeItem('recorded_scripts_length');

        console.log("Requesting Recorder ...");
        requestRecorder().then(setRecorder, console.error)
    }, []);

    useEffect(() => {
        console.log("COUNT: ", count);
    }, [count]);

    const handleData = async (e: any) => {
        console.log("Data stop : ", e.data);
        if (e.data == null || e.data.size == 0) {
            ToastFailedMsg("রেকর্ডিং ব্যর্থ হয়েছে !");
            setCount(count - 1);
            return
        }
        const blobDataInWebaFormat = e.data; // .weba = webaudio; subset of webm
        //blobDataInWebaFormat.text().then(response => console.log(response))
        const blobDataInWebmFormat: Blob = new Blob([blobDataInWebaFormat], { type: e.data.type });
        setFormat(e.data.type.toString());
        console.log("blobData", blobDataInWebmFormat);
        setWavData(blobDataInWebmFormat);
        getDuration(blobDataInWebmFormat);

        setTimeout(async () => {
            console.log("In Audio Recorder ... !");

            console.log("word count: ", localStorage.getItem("latest_word_count"));
            // console.log("syllable count: ", localStorage.getItem("latest_syllable_count"));
            console.log("phoneme count: ", localStorage.getItem("latest_phoneme_count"));
            console.log("duration", localStorage.getItem("latest_duration"));

            let latest_duration_str = localStorage.getItem("latest_duration");
            let currentAudioDuration = latest_duration_str ? parseFloat(latest_duration_str) : 0.00;

            let word_count_str = localStorage.getItem("latest_word_count")
            let word_count = word_count_str ? parseInt(word_count_str) : 0;
            let words_per_second = word_count / currentAudioDuration;

            // let syllable_count_str = localStorage.getItem("latest_syllable_count");
            // let syllable_count = syllable_count_str ? parseInt(syllable_count_str) : 0;
            // let syllables_per_second = syllable_count / currentAudioDuration;

            let phoneme_count_str = localStorage.getItem("latest_phoneme_count");
            let phoneme_count = phoneme_count_str ? parseInt(phoneme_count_str) : 0;
            let phonemes_per_second = phoneme_count / currentAudioDuration;

            console.log("wps & pps AudioRecorder:", words_per_second, phonemes_per_second)
            if (
                (props.type == 0 && currentAudioDuration >= 0.5 && (phonemes_per_second >= 5 && phonemes_per_second <= 15))
                || (props.type == 1)
                || (props.type == 2 && currentAudioDuration >= 1.00 && (phonemes_per_second >= 5 && phonemes_per_second <= 15))
                // || (props.type == 2 && currentAudioDuration > 1.00 && )
                // ((words_per_second >= 0.5 && words_per_second <= 4.00) ||
                //     (syllables_per_second >= 1.00 && syllables_per_second <= 10.00))
            ) {
                const d = new Date();
                let ms = d.valueOf();
                let name = ms + ".wav";
                console.log("Now date ", name);

                const audioData = new File([e.data], name, { type: e.data.type, lastModified: Date.now() });

                console.log("Audio in wav format ", audioData);

                console.log("WavData", blobDataInWebmFormat);

                //    const formData = new FormData();

                //    formData.append("file", blobDataInWebmFormat);

                //     // console.log(obj);

                //     // await uploadAudio(formData); 

                const blobUrl = URL.createObjectURL(blobDataInWebmFormat);

                setAudioURL(URL.createObjectURL(blobDataInWebmFormat));


                if (reRecordingNumber !== "") {
                    setAudios({ ...audios, [reRecordingNumber]: URL.createObjectURL(blobDataInWebmFormat) });

                    setRecordedData({
                        ...recordedData,
                        [reRecordingNumber]: blobDataInWebmFormat
                    });
                    setAudioTime({ ...audioTime, [`time${count}`]: await getBlobDuration(blobDataInWebmFormat) });
                    // if (reRecordingNumber === "audio1") {
                    //     setAudioTime({
                    //         ...audioTime,
                    //         ['time1']: await getBlobDuration(blobDataInWebmFormat)
                    //     })
                    // } else if (reRecordingNumber === "audio2") {
                    //     setAudioTime({
                    //         ...audioTime,
                    //         ['time2']: await getBlobDuration(blobDataInWebmFormat)
                    //     })
                    // } else if (reRecordingNumber === "audio3") {
                    //     setAudioTime({
                    //         ...audioTime,
                    //         ['time3']: await getBlobDuration(blobDataInWebmFormat)
                    //     })
                    // } else if (reRecordingNumber === "audio4") {
                    //     setAudioTime({
                    //         ...audioTime,
                    //         ['time4']: await getBlobDuration(blobDataInWebmFormat)
                    //     })
                    // } else {
                    //     setAudioTime({
                    //         ...audioTime,
                    //         ['time5']: await getBlobDuration(blobDataInWebmFormat)
                    //     })
                    // }

                    setRerecordingNumber("");
                    setRerecordingState(false);

                } else {
                    console.log("Here We GO !!!");
                    console.log("COUNT: ", count);

                    /*testing*/

                    setAudios({ ...audios, [`audio${count}`]: URL.createObjectURL(blobDataInWebmFormat) });
                    setRecordedData({
                        ...recordedData,
                        [`audio${count}`]: audioData
                    });
                    setAudioTime({
                        ...audioTime,
                        [`time${count}`]: await getBlobDuration(blobDataInWebmFormat)
                    });
                    console.log("Time ", audioTime);
                }


                const audio = new Audio(blobUrl);
                setAudio(audio);
            }
            else {
                if (reRecordingNumber == "") {
                    setCount(count - 1);
                }
                if (phonemes_per_second > 15) {
                    ToastFailedMsg("বেশি দ্রুত পড়েছেন।");
                }
                else if (phonemes_per_second < 5) {
                    ToastFailedMsg("বেশি ধীরে পড়েছেন।");
                }
            }
        }, 50);
    };

    useEffect(() => {
        if (recorder !== null) {
            console.log("Recorder Initiated !!!");
            // recorder.ondataavailable = (e: any) => {
            //     // Obtain the audio when ready.
            //     console.log("Recorder Stopped !!!");
            //     handleData(e)
            // }
        }
    }, [recorder]);

    useEffect(() => {
        if (recorder !== null) {
            console.log("RecordingAction ", recordingAction, " Is Recording: ", isRecording);

            recorder.ondataavailable = (e: any) => {
                // Obtain the audio when ready.
                // ToastSuccessMsg("Recorded", 500);
                console.log("ondataavailable fired!!!");
                handleData(e);
            }

            // Manage recorder state.
            if (isRecording && recordingAction === 'start') {
                recorder.start();
                setCheckRecording(true);
                console.log("here recorder state ", recorder);
                console.log('count', count);
                console.log('msg', 'start');
            } else if (isRecording && recordingAction === 'pause') {
                recorder.pause();
                setCheckRecording(false);
                console.log('msg', 'pause');
            } else if (isRecording && recordingAction === 'resume') {
                recorder.resume();
                setCheckRecording(true);
                console.log('msg', 'resume');
            } else {

                if (recorder.state === 'inactive')
                    return;

                if (recorder) recorder.stop();
                setCheckRecording(false);

                // // Obtain the audio when ready.
                // recorder.addEventListener("dataavailable", handleData);
                // return () => recorder.removeEventListener("dataavailable", handleData);
            }
        }
    }, [isRecording]);

    const getDuration = async (blob: any) => {
        const duration = await getBlobDuration(blob);
        // localStorage.setItem("latest_duration", String(duration.toFixed(5)));
        console.log(duration, ' seconds ');
    }

    const startRecording = (value: string) => {
        console.log("IS it here ", value);

        setRecordingAction(value);
        setIsRecording(true);
    };

    const pauseRecording = (value: string) => {
        setIsRecording(2);
        setRecordingAction(value);
    };

    const resumeRecording = (value: string) => {
        setIsRecording(3);
        setRecordingAction(value);
    };

    const stopRecording = (value: string) => {
        console.log("STOP Recording ... ", value);
        if (reRecordingNumber === "") {
            setCount(count + 1);
        }

        setRecordingAction(value);
        setIsRecording(false);
    };

    const fun = (value: any) => {

        console.log("Fun", value.recording_area);

        upload(value);
    }

    const delay = () => {
        if (getLoggedInUserId() === undefined) {
            navigate('/log-in')
        }
    }

    let crowdData: any = new FormData();
    const upload = async (value: any) => {
        console.log("WavData", wavData, value.recording_area, value.speaker_id);


        let formData = {}

        formData = {
            ...formData,
            speaker_id: value.speaker_id,
            recording_area: value.recording_area,
            details: value.details
        }

        if (props.type == 0) {
            formData = {
                ...formData,
                script_id1: scriptId[0],
                script_id2: scriptId[1],
                script_id3: scriptId[2],
                script_id4: scriptId[3],
                script_id5: scriptId[4]
            }
        }
        else if (props.type == 1) {
            formData = {
                ...formData,
                topic: value.topic,
                speaker_list: value.speaker_list
            }
        }
        else if (props.type == 2) {
            formData = {
                ...formData,
                parent_id: value.parent_id
            }
        }

        console.log("Script Id ", formData);



        if (props.type == 0) {
            crowdData.append("audioFile1", recordedData.audio1);
            crowdData.append("audioFile2", recordedData.audio2);
            crowdData.append("audioFile3", recordedData.audio3);
            crowdData.append("audioFile4", recordedData.audio4);
            crowdData.append("audioFile5", recordedData.audio5);
        }
        else if (props.type == 1) {
            crowdData.append("audioFile", recordedData.audio1);
        }
        else if (props.type == 2) {
            for (let i = 1; i <= count; i++) {
                crowdData.append("audioList", recordedData[`audio${i}`]);
            }
        }

        crowdData.append("scripts_id", JSON.stringify(formData));
        crowdData.append("audio_time", JSON.stringify(audioTime));
        crowdData.append("fmt", format);
        console.log("crowdData ", crowdData);

        let result = props.type == 0 ? await uploadCrowdSourceAudio(crowdData) : props.type == 1 ? await submitConvData(crowdData) :
            await submitLectureData(crowdData);



        console.log("Result", result);

        if (result?.status == 200) {
            console.log("Data uploaded");
            ToastSuccessMsg("প্রক্রিয়াধীন, কিছুক্ষণ পর ড্যাশ বোর্ড চেক করুন।");

            if (getLoggedInUserId() === undefined) {
                setTimeout(() => delay(), 2000);
            }
            setTimeout(() => window.location.reload(), 500);

        } else if (result?.status == 403) {
            console.log("Data is not uploaded");
            ToastFailedMsg("জমা হয়নি। Username does Not Exist!!! Logout first, then Login again or Register!!!");
            setTimeout(() => { }, 4000);
        }
        else {
            console.log("Data is not uploaded");
            ToastFailedMsg("জমা হয়নি");
            setTimeout(() => { }, 4000);
        }

    }


    return [audioURL, setAudioURL, isRecording, startRecording, stopRecording, pauseRecording, resumeRecording, fun, checkRecording, count, audios, reRecordingState, setRerecordingState, reRecordingNumber, setRerecordingNumber, setScriptId, scriptId];
};

async function requestRecorder() {
    // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
            channelCount: 1,
            sampleRate: 44100

        }
    });

    // stream.getAudioTracks().forEach((item:any, index:number) => {
    //  //   item.stop();
    // })

    var options = { "type": "audio/webm" }; //vp9 video/webm

    // @ts-ignore
    // return new MediaRecorder(stream,options);
    return new MediaRecorder(stream);
}
// async function requestRecorder() {
//     // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

//     await register(await connect());

//     const stream = await navigator.mediaDevices.getUserMedia({
//         audio: {
//             channelCount: 1,
//             sampleRate: 44100
//         }
//     });
//     //    const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/wav' });

//     // const stream = await navigator.mediaDevices.getUserMedia({ audio: {
//     //     channelCount: 1,
//     //     sampleRate:44100

//     // }});

//     // var options = { "type": "audio/wav"}; //vp9 video/webm

//     // @ts-ignore
//     return new MediaRecorder(stream, { mimeType: 'audio/wav' });
// }

export default useRecorder;
